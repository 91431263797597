import { Box, Typography } from '@material-ui/core'
import { useLicensesCount } from 'app/db/db-hooks/main-db-hooks'
import { ElevatedBox } from 'app/pages/dashboard/elevated-box'
import { User } from 'app/users/user'
import { routes } from 'shared/config/routes'
import { t } from 'shared/i18n/current'
import { RoutedButton } from 'utils/buttons/routed-button'
import { FriendlyError } from 'utils/errors'
import { FlexButtons } from 'utils/flex-buttons'
import { Loading } from 'utils/loading'

interface LicensesOverviewProps {
  user: User
}

export function LicensesOverview(_props: LicensesOverviewProps) {
  const { data, loading, error, loadingOrError } = useLicensesCount()
  const highlighted = { color: 'primary', variant: 'outlined' } as const

  return (
    <ElevatedBox title={t().dashboard.licenses}>
      <FriendlyError error={error} />
      <Loading loading={loading} />

      {!loadingOrError && (
        <>
          <Box mb={2}>
            <Typography>
              {t().licenses.drafts}: {data.drafts}, {t().licenses.approved}: {data.approved}
            </Typography>
          </Box>
          <Box>
            <FlexButtons>
              <RoutedButton to={routes.licenseDrafts.to} {...(data.drafts ? highlighted : {})}>
                {routes.licenseDrafts.text()}
              </RoutedButton>
              <RoutedButton to={routes.approvedLicenses.to} {...(data.drafts ? {} : highlighted)}>
                {routes.approvedLicenses.text()}
              </RoutedButton>
              <RoutedButton to={routes.assignLicense.to}>{routes.assignLicense.text()}</RoutedButton>
              <RoutedButton to={routes.categoriesKeyFigures.to}>
                {routes.categoriesKeyFigures.text()}
              </RoutedButton>
              <RoutedButton to={routes.licenseBookingsByRider.to}>
                {routes.licenseBookingsByRider.text()}
              </RoutedButton>
              <RoutedButton to={routes.allLicenseBookings.to}>
                {routes.allLicenseBookings.text()}
              </RoutedButton>
              {/* currently disabled: <RoutedButton to={routes.importLicenses.to}>{routes.importLicenses.text()}</RoutedButton> */}
            </FlexButtons>
          </Box>
        </>
      )}
    </ElevatedBox>
  )
}
