import { constCategories2021 } from 'shared/data/categories-2021'
import { constCategories2022 } from 'shared/data/categories-2022'
import { constCategories2023 } from 'shared/data/categories-2023'
import { MergedUntranslatedCategory } from 'shared/models/category'
import { truthy } from 'shared/utils/array'

export function map2023FormatToCurrentFormat(
  categories: typeof constCategories2023
): MergedUntranslatedCategory[] {
  return categories as any as MergedUntranslatedCategory[]
}

export function map2022FormatToCurrentFormat(
  categories: typeof constCategories2022
): MergedUntranslatedCategory[] {
  // TODO: later: remove any?
  return categories as any as MergedUntranslatedCategory[]
}

export function map2021FormatToCurrentFormat(
  categories: typeof constCategories2021
): MergedUntranslatedCategory[] {
  return categories.map((category) => ({
    id: category.id,
    active: category.active,
    archived: category.archived,
    sidecar: category.sidecar,
    sidecarPassenger: category.id === 'motocross-seitenwagen-passagier',
    type: category.type,
    transponders: [category.transponder].filter(truthy),
    commonNameDe: category.nameDe,
    commonNameEn: category.nameEn,
    commonNameFr: category.nameFr,
    startListName: category.myLapsName,
    shortId: category.shortId,
    associations: ['sam'],
    numberChoice: category.id !== 'other-trainingslizenz-funlizenz',
    importName: category.importName,
    year: 2021,
    categoryType: 'licenseCategory',
    enlistWithoutLicense: false,
    groups: { enabled: false, defaultMaxGroups: 1 },
    associationSpecificDetails: [
      {
        association: 'sam',
        prices: category.prices.map((price) => ({
          association: price.association,
          value: price.price,
          licenseType: 'national',
          from: 'from' in price ? price.from : undefined,
          to: 'to' in price ? price.to : undefined,
        })),
        priceHintDe: category.priceHintDe,
        priceHintEn: category.priceHintEn,
        priceHintFr: category.priceHintFr,
        priceAdditionalLicense: category.priceAdditionalLicense,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: category.priceInsuranceBookkeeping,
        priceInscriptionWithLicense: category.priceInscriptionWithLicense,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: category.priceInscriptionPower,
        priceInscriptionSurcharge: category.priceInscriptionSurcharge,
        priceInscriptionHoursBeforeEvent: category.priceInscriptionHoursBeforeEvent,
        surcharge: category.surcharge,
        surchargeAfter: category.surchargeAfter,
        discount: category.discount,
        discountUntil: category.discountUntil,
        hintDe: category.hintDe,
        hintEn: category.hintEn,
        hintFr: category.hintFr,
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsHealthCheck: [
          {
            from: undefined,
            to: undefined,
            value: category.needsHealthCheck,
            association: 'sam',
            licenseType: 'national',
          },
        ],
        needsBikeInfo: false,
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsInsurance: category.needsInsurance,
        needsEmergency: category.needsEmergency,
        showAdditionalInsuranceForm: category.showAdditionalInsuranceForm,
        oneEventLicense: false,
        myLapsName: category.myLapsName,
        startListName: category.myLapsName,
        myLapsSpecialExportFormatting: category.myLapsSpecialExportFormatting,
        colorBackground: category.colorBackground,
        colorForeground: category.colorForeground,
        colorLicense: category.colorLicense,
        numberFixed: category.numberFixed,
        nameDe: category.nameDe,
        nameEn: category.nameEn,
        nameFr: category.nameFr,
        shortNameDe: category.shortNameDe,
        shortNameEn: category.shortNameEn,
        shortNameFr: category.shortNameFr,
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
        priceDayLicenseWithOtherLicenseTwoDays: 0,
      },
    ],
  }))
}
