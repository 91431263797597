import { Box, createStyles, Grid, makeStyles, Typography } from '@material-ui/core'
import type { Theme } from '@material-ui/core'
import { CheckCircle, Cancel } from '@material-ui/icons'
import { useFormikContext } from 'formik'
import { Fragment } from 'react'
import { useApprovedLicense } from 'app/db/db-hooks/main-db-hooks'
import { db } from 'app/db/frontend-db'
import { samFields } from 'app/forms/fields'
import { IconButtonWithConfirmationAndErrorHandling } from 'app/layout/button-with-confirmation'
import { LicenseDraftConfirmValues } from 'app/license/forms/admin-form-types'
import { useUserContext } from 'app/themes/user-context'
import { useLicenseYear } from 'app/themes/year-context'
import { categoryById, categoryCommonName, fixedLicenseType } from 'shared/data/categories-service'
import {
  deleteApprovedLicense,
  invalidateApprovedLicense,
  validateApprovedLicense,
} from 'shared/data/licenses-service'
import { ApprovedLicense } from 'shared/db/db'
import { t, todoT } from 'shared/i18n/current'
import { DeleteButtonIcon } from 'utils/buttons/delete-button-icon'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({ gridParent: { '& > *': { margin: theme.spacing(1, 0, 0, 0) } } })
)

export function AdministrateApprovedCategories() {
  const classes = useStyles()

  const { values } = useFormikContext<LicenseDraftConfirmValues>()
  const relevantApprovedLicenses = values.categoryDetails.approved.map((row) => fixedLicenseType(row))

  return (
    <Box mb={2}>
      <Typography variant="h4" component="h3" gutterBottom>
        {t().licenses.approvedLicenses}
      </Typography>

      <Grid container spacing={1} className={classes.gridParent}>
        {relevantApprovedLicenses.length ? (
          relevantApprovedLicenses.map((approvedLicense, index) => (
            <AdministrateApprovedCategory key={index} approvedLicense={approvedLicense} index={index} />
          ))
        ) : (
          <Grid item>{t().none}</Grid>
        )}
      </Grid>
    </Box>
  )
}

interface AdministrateApprovedCategoryProps {
  approvedLicense: ApprovedLicense
  index: number
}

function AdministrateApprovedCategory({ approvedLicense, index }: AdministrateApprovedCategoryProps) {
  const category = categoryById(approvedLicense.categoryId)
  const fields = samFields().categoriesDropdown
  const userContext = useUserContext()
  const shouldDisplay = userContext.canViewApprovedLicense(approvedLicense)
  const currentYear = useLicenseYear()
  const needsBikeInfo = category?.associationSpecificDetails[0].needsBikeInfo
  const currentApprovedCategory = useApprovedLicense(
    approvedLicense,
    category?.year || currentYear,
    approvedLicense.categoryId
  )

  return category && shouldDisplay ? (
    <Fragment key={`${index}-${approvedLicense.categoryId}-${approvedLicense.association}`}>
      <Grid item xs={6}>
        {fields.categoryId.field(
          'approved',
          index,
          userContext.associationAdmin || undefined,
          currentYear,
          { disabled: true }
        )}
      </Grid>
      <Grid item xs={6}>
        <Box display="flex">
          <BoxMR>{fields.licenseType.field('approved', index, { disabled: true })}</BoxMR>
          <BoxMR>{fields.association.field('approved', index, { disabled: true })}</BoxMR>
          {category.numberChoice && <BoxMR>{fields.issuedNumber.field(index)}</BoxMR>}
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box display="flex">
          {needsBikeInfo && <BoxMR>{fields.teamName.field('approved', index)}</BoxMR>}
          {needsBikeInfo && <BoxMR>{fields.bikeMake.field('approved', index)}</BoxMR>}
          {category.sidecar && <BoxMR>{fields.sidecarPartner.field('approved', index)}</BoxMR>}
          <Box alignSelf="center" p={1}>
            {currentApprovedCategory.data?.invalidated ? (
              <IconButtonWithConfirmationAndErrorHandling
                title={todoT(`Lizenz ${categoryCommonName(category)} wieder gültig schalten`)}
                tooltip={todoT(`Lizenz ${categoryCommonName(category)} wieder gültig schalten`)}
                confirmation={todoT('Lizenz wieder gültig schalten?')}
                onConfirm={() => validateApprovedLicense(db, approvedLicense, category)}
                disabled={!userContext.canEditApprovedLicense(approvedLicense)}
              >
                <CheckCircle />
              </IconButtonWithConfirmationAndErrorHandling>
            ) : (
              <IconButtonWithConfirmationAndErrorHandling
                title={todoT(`Lizenz ${categoryCommonName(category)} ungültig schalten`)}
                tooltip={todoT(`Lizenz ${categoryCommonName(category)} ungültig schalten`)}
                confirmation={todoT(
                  'Lizenz ungültig schalten? Bestehende Einschreiben bleiben gültig. Neue Einschreiben sind nicht mehr möglich'
                )}
                onConfirm={() => invalidateApprovedLicense(db, approvedLicense, category)}
                disabled={!userContext.canEditApprovedLicense(approvedLicense)}
              >
                <Cancel />
              </IconButtonWithConfirmationAndErrorHandling>
            )}
            <DeleteButtonIcon
              title={todoT(`Lizenz ${categoryCommonName(category)} löschen`)}
              onConfirm={() => deleteApprovedLicense(db, approvedLicense, category)}
              disabled={!userContext.canEditApprovedLicense(approvedLicense)}
            />
          </Box>
        </Box>
      </Grid>
    </Fragment>
  ) : null
}

function BoxMR({ children }: { children: React.ReactNode }) {
  return (
    <Box flexGrow={1} style={{ marginRight: '8px' }}>
      {children}
    </Box>
  )
}
