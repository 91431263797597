import { Section } from 'shared/data/section-interface'

export const constSections = [
  { id: 2001, association: 'afm', acceptsNewMembers: true, name: '- Aucun club AFM / Kein AFM Club / No AFM club' },
  { id: 2002, association: 'afm', acceptsNewMembers: true, name: 'AMC Aumont' },
  { id: 2003, association: 'afm', acceptsNewMembers: true, name: 'AMC Le Locle' },
  { id: 2004, association: 'afm', acceptsNewMembers: true, name: 'AMC Le Mouret' },
  { id: 2005, association: 'afm', acceptsNewMembers: true, name: "Fan's Club VG92" },
  { id: 2006, association: 'afm', acceptsNewMembers: true, name: 'MC Broye' },
  { id: 2008, association: 'afm', acceptsNewMembers: true, name: 'MC Chevroux' },
  { id: 2007, association: 'afm', acceptsNewMembers: true, name: 'MC Châtel-St-Denis' },
  { id: 2009, association: 'afm', acceptsNewMembers: true, name: 'MC Enduroteam Schwarzenburg' },
  { id: 2010, association: 'afm', acceptsNewMembers: true, name: 'MC Frauenkappelen' },
  { id: 2011, association: 'afm', acceptsNewMembers: true, name: 'MC Glânois' },
  { id: 2012, association: 'afm', acceptsNewMembers: true, name: 'MC Gruyère' },
  { id: 2013, association: 'afm', acceptsNewMembers: true, name: 'MC Indonina' },
  { id: 2014, association: 'afm', acceptsNewMembers: true, name: 'MC Prez' },
  { id: 2015, association: 'afm', acceptsNewMembers: true, name: 'MC Racle Bitume St-Martin' },
  { id: 2016, association: 'afm', acceptsNewMembers: true, name: 'MC Sense' },
  { id: 2017, association: 'afm', acceptsNewMembers: true, name: 'MC Team Vully' },
  { id: 2019, association: 'afm', acceptsNewMembers: true, name: 'MC Tout Terrain' },
  { id: 2018, association: 'afm', acceptsNewMembers: true, name: 'MC Töff Club Seisa-See' },
  { id: 2020, association: 'afm', acceptsNewMembers: true, name: 'MCC Les Meyrinos' },
  { id: 2021, association: 'afm', acceptsNewMembers: true, name: 'MCR Belfaux' },
  { id: 2022, association: 'afm', acceptsNewMembers: true, name: 'Sick Boys Motoclub' },
  {
    id: 1001,
    association: 'fms',
    acceptsNewMembers: true,
    name: '- Keine Swiss Moto Sektion / Aucun section Swiss Moto / No Swiss Moto section',
  },
  { id: 1002, association: 'fms', acceptsNewMembers: true, name: 'A01 - Swiss Moto Direktmitglied' },
  { id: 1003, association: 'fms', acceptsNewMembers: true, name: 'AG02 - MC Ehrendingen' },
  { id: 1004, association: 'fms', acceptsNewMembers: true, name: 'AG11 - Gilera Club Aargau' },
  { id: 1005, association: 'fms', acceptsNewMembers: true, name: 'AG20 - MC Ruedertal' },
  { id: 1006, association: 'fms', acceptsNewMembers: true, name: 'AG21 - FMS Sektion Aargau' },
  { id: 1007, association: 'fms', acceptsNewMembers: true, name: 'AG22 - MRS Club Muri' },
  { id: 1008, association: 'fms', acceptsNewMembers: true, name: 'AG23 - Gelände-Club Wettingen' },
  { id: 1009, association: 'fms', acceptsNewMembers: true, name: 'AG25 - MC Schupfart' },
  { id: 1010, association: 'fms', acceptsNewMembers: true, name: 'AG27 - MSCW Wohlen' },
  { id: 1011, association: 'fms', acceptsNewMembers: true, name: 'BE01 - Motocross Club Büttenberg' },
  { id: 1012, association: 'fms', acceptsNewMembers: true, name: 'BE07 - MAC Bümpliz' },
  { id: 1013, association: 'fms', acceptsNewMembers: true, name: 'BE09 - MC Dotzigen' },
  { id: 1039, association: 'fms', acceptsNewMembers: true, name: 'BE100 - MF Frauenkappelen' },
  { id: 1040, association: 'fms', acceptsNewMembers: true, name: 'BE101 - Vulcan Riders Switzerland' },
  { id: 1014, association: 'fms', acceptsNewMembers: true, name: 'BE12 - MC Innerberg' },
  { id: 1015, association: 'fms', acceptsNewMembers: true, name: 'BE20 - MC St-Stephan' },
  { id: 1016, association: 'fms', acceptsNewMembers: true, name: 'BE22 -  MC Aarberg' },
  { id: 1017, association: 'fms', acceptsNewMembers: true, name: 'BE27 - MRT Linden' },
  { id: 1018, association: 'fms', acceptsNewMembers: true, name: 'BE28 - MC du Cornet' },
  { id: 1019, association: 'fms', acceptsNewMembers: false, name: 'BE34 - Moto-Club la Zone' },
  { id: 1020, association: 'fms', acceptsNewMembers: true, name: 'BE47 - MC Tavannes' },
  { id: 1021, association: 'fms', acceptsNewMembers: true, name: 'BE48 - AMC Wangen a.A. und Umgebung' },
  { id: 1022, association: 'fms', acceptsNewMembers: true, name: 'BE49 - MC Gümmenen' },
  { id: 1023, association: 'fms', acceptsNewMembers: true, name: 'BE51 - MC Heimberg' },
  { id: 1024, association: 'fms', acceptsNewMembers: true, name: 'BE65 - MC Koppigen' },
  { id: 1025, association: 'fms', acceptsNewMembers: true, name: 'BE72 - MSC Langnau' },
  { id: 1026, association: 'fms', acceptsNewMembers: true, name: 'BE76 - MC Mont-Crosin' },
  { id: 1027, association: 'fms', acceptsNewMembers: true, name: 'BE77 - Moto-Club Trial 96' },
  { id: 1028, association: 'fms', acceptsNewMembers: true, name: 'BE86 - RC Isadora' },
  { id: 1029, association: 'fms', acceptsNewMembers: true, name: 'BE87 - Moto-Club Golden-Flèches' },
  { id: 1030, association: 'fms', acceptsNewMembers: true, name: 'BE88 - Motoneige Club "L\'Orignal"' },
  { id: 1031, association: 'fms', acceptsNewMembers: true, name: 'BE91 - Moto-Sport Tramelan' },
  { id: 1032, association: 'fms', acceptsNewMembers: true, name: 'BE92 - Swiss Safari Rallye Team' },
  { id: 1033, association: 'fms', acceptsNewMembers: true, name: 'BE93 - Gelände Team Schweiz GTS' },
  { id: 1034, association: 'fms', acceptsNewMembers: true, name: 'BE94 - MC Corgémont' },
  { id: 1035, association: 'fms', acceptsNewMembers: true, name: 'BE95 - MC Street Eagles' },
  { id: 1036, association: 'fms', acceptsNewMembers: true, name: 'BE96 - Trial Club Schwenden' },
  { id: 1037, association: 'fms', acceptsNewMembers: true, name: 'BE97 - MC Roches' },
  { id: 1038, association: 'fms', acceptsNewMembers: true, name: 'BE98 - Moto Club Sprint Bienne' },
  { id: 1041, association: 'fms', acceptsNewMembers: true, name: 'BL09 - MC Roggenburg' },
  { id: 1042, association: 'fms', acceptsNewMembers: true, name: 'BL13 - MC Central Basel' },
  { id: 1043, association: 'fms', acceptsNewMembers: true, name: 'FR03 - MAC Fribourg' },
  { id: 1044, association: 'fms', acceptsNewMembers: true, name: 'FR04 - AMC Le Mouret' },
  { id: 1045, association: 'fms', acceptsNewMembers: true, name: 'FR09 - Moto Club Glânois' },
  { id: 1046, association: 'fms', acceptsNewMembers: true, name: 'FR10 - Moto-Club de la Gruyère' },
  { id: 1047, association: 'fms', acceptsNewMembers: true, name: 'FR16 - MC Les Couraprès' },
  { id: 1048, association: 'fms', acceptsNewMembers: true, name: "FR17 - Le Lion's Moto-Club" },
  { id: 1049, association: 'fms', acceptsNewMembers: true, name: 'FR19 - MC Racle-Bitume St-Martin' },
  { id: 1050, association: 'fms', acceptsNewMembers: true, name: 'FR20 - MCR Belfaux' },
  { id: 1051, association: 'fms', acceptsNewMembers: true, name: 'FR24 - MC Aumont' },
  { id: 1052, association: 'fms', acceptsNewMembers: true, name: 'FR27 - Moto Club Sense' },
  { id: 1053, association: 'fms', acceptsNewMembers: true, name: 'FR30 - Moto Club Tout Terrain' },
  { id: 1054, association: 'fms', acceptsNewMembers: true, name: 'FR31 - Moto-Club Châtel-St-Denis' },
  { id: 1055, association: 'fms', acceptsNewMembers: true, name: 'FR34 - Moto Club Adrénaline' },
  { id: 1056, association: 'fms', acceptsNewMembers: true, name: 'FR35 - Töff Club Seisa-See' },
  { id: 1057, association: 'fms', acceptsNewMembers: true, name: 'GE01 - Trial Moto Club Genève' },
  { id: 1058, association: 'fms', acceptsNewMembers: true, name: 'GE07 - MSC Genève' },
  { id: 1059, association: 'fms', acceptsNewMembers: true, name: 'GE11 - MC Rolling Biker' },
  { id: 1060, association: 'fms', acceptsNewMembers: true, name: 'GE12 - Norton Sport-Club Genève' },
  { id: 1061, association: 'fms', acceptsNewMembers: false, name: 'GE15 - Auto-Moto-Club Genève' },
  { id: 1062, association: 'fms', acceptsNewMembers: true, name: 'GE18 - MCC Les Meyrinos' },
  { id: 1063, association: 'fms', acceptsNewMembers: true, name: 'GE19 - Club Motocycliste Suisse de la Police' },
  { id: 1064, association: 'fms', acceptsNewMembers: true, name: 'GE22 - Pure Power Genève Motocross Club' },
  { id: 1065, association: 'fms', acceptsNewMembers: true, name: 'GR01 - MC Chur u. Umgebung' },
  { id: 1066, association: 'fms', acceptsNewMembers: true, name: 'GR02 - Bultaco-Club Ftan' },
  { id: 1067, association: 'fms', acceptsNewMembers: true, name: 'GR03 - MCC Schaan' },
  { id: 1068, association: 'fms', acceptsNewMembers: true, name: 'GR04 - MC Valposchiavo' },
  { id: 1069, association: 'fms', acceptsNewMembers: true, name: 'GR07 - MRC Vorderrheintal' },
  { id: 1070, association: 'fms', acceptsNewMembers: true, name: 'JU01 - Moto Club Jurassien' },
  { id: 1071, association: 'fms', acceptsNewMembers: true, name: 'JU04 - Motoclub Montchoisi' },
  { id: 1072, association: 'fms', acceptsNewMembers: true, name: 'JU05 - Triumph Club Vicques' },
  { id: 1073, association: 'fms', acceptsNewMembers: true, name: 'JU06 - AMC Ederswiler' },
  { id: 1074, association: 'fms', acceptsNewMembers: true, name: 'JU07 - MC Les Grottes' },
  { id: 1075, association: 'fms', acceptsNewMembers: true, name: 'JU08 - Boécourt Moto Racing Team' },
  { id: 1076, association: 'fms', acceptsNewMembers: true, name: 'JU10 - AMC Franc-Montagnard' },
  { id: 1077, association: 'fms', acceptsNewMembers: true, name: 'JU11 - Off Road Team Ajoie' },
  { id: 1078, association: 'fms', acceptsNewMembers: true, name: 'LU14 - Moto Racing Team Sursee' },
  { id: 1079, association: 'fms', acceptsNewMembers: true, name: 'LU17 - Töff Team Grosswangen Team Cross' },
  { id: 1080, association: 'fms', acceptsNewMembers: true, name: 'NE03 - Moto-Club Les Bayards' },
  { id: 1081, association: 'fms', acceptsNewMembers: true, name: 'NE04 - AMC Côte-Neuchâteloise' },
  { id: 1082, association: 'fms', acceptsNewMembers: true, name: 'NE05 - MC des Neiges' },
  { id: 1083, association: 'fms', acceptsNewMembers: true, name: 'NE06 - AMC Le Locle' },
  { id: 1084, association: 'fms', acceptsNewMembers: true, name: 'NE10 - MC Lucky Boys' },
  { id: 1085, association: 'fms', acceptsNewMembers: true, name: 'NE14 - MC Les Centaures' },
  { id: 1086, association: 'fms', acceptsNewMembers: true, name: 'NE16 - MC La Vallée' },
  { id: 1087, association: 'fms', acceptsNewMembers: true, name: 'NE17 - Trial Hauts-Geneveys' },
  { id: 1088, association: 'fms', acceptsNewMembers: true, name: 'NE19 - MC de la Béroche' },
  { id: 1089, association: 'fms', acceptsNewMembers: true, name: 'NE21 - MX Team Béroche' },
  { id: 1090, association: 'fms', acceptsNewMembers: true, name: 'NW01 - MFC Nidwalden' },
  { id: 1091, association: 'fms', acceptsNewMembers: true, name: 'NW03 - MMRC California' },
  { id: 1092, association: 'fms', acceptsNewMembers: true, name: 'OW02 - Moto-Club Obwalden' },
  { id: 1093, association: 'fms', acceptsNewMembers: true, name: 'OW03 - Offroad Team Pilatus ORTP' },
  { id: 1094, association: 'fms', acceptsNewMembers: true, name: 'SG08 - MSC Wil FMS' },
  { id: 1095, association: 'fms', acceptsNewMembers: true, name: 'SH03 - Munot Racing Team' },
  { id: 1096, association: 'fms', acceptsNewMembers: true, name: 'SO05 - MC Niederwil' },
  { id: 1097, association: 'fms', acceptsNewMembers: true, name: 'SO07 - FAM-FHRM' },
  { id: 1098, association: 'fms', acceptsNewMembers: true, name: 'SO14 - Moto-Sport-Club Limpachtal' },
  { id: 1099, association: 'fms', acceptsNewMembers: true, name: 'SO15 - MXRS MX Club' },
  { id: 1100, association: 'fms', acceptsNewMembers: true, name: 'SO16 - Motoclub Passwang' },
  { id: 1101, association: 'fms', acceptsNewMembers: true, name: 'SZ04 - MSC Innerschweiz' },
  { id: 1102, association: 'fms', acceptsNewMembers: true, name: 'TG07 - MC Thundorf' },
  { id: 1103, association: 'fms', acceptsNewMembers: true, name: 'TG08 - MSC Bodan Arbon u. Umgebung' },
  { id: 1104, association: 'fms', acceptsNewMembers: true, name: 'TG10 - MRSV Frauenfeld' },
  { id: 1105, association: 'fms', acceptsNewMembers: true, name: 'TG11 - Motocrossclub Mittelthurgau' },
  { id: 1106, association: 'fms', acceptsNewMembers: true, name: 'TG12 - Race2Win' },
  { id: 1107, association: 'fms', acceptsNewMembers: true, name: 'TI03 - Auto Moto Club Generoso' },
  { id: 1108, association: 'fms', acceptsNewMembers: true, name: 'TI04 - Unione Motoristica Locarnese' },
  { id: 1109, association: 'fms', acceptsNewMembers: true, name: 'TI05 - AMC Malcantonese' },
  { id: 1110, association: 'fms', acceptsNewMembers: true, name: 'TI06 - AMC Tesserete' },
  { id: 1111, association: 'fms', acceptsNewMembers: true, name: 'TI07 - Moto Auto Club Biasca' },
  { id: 1112, association: 'fms', acceptsNewMembers: true, name: 'TI09 - MC Midnight Riders' },
  { id: 1113, association: 'fms', acceptsNewMembers: true, name: 'TI11 - Moto Club 3 Stelle' },
  { id: 1114, association: 'fms', acceptsNewMembers: false, name: 'TI12 - MC Condor' },
  { id: 1115, association: 'fms', acceptsNewMembers: true, name: 'TI14 - Harley-Davidson Club Ticino' },
  { id: 1116, association: 'fms', acceptsNewMembers: true, name: 'TI15 - Belt Racing' },
  { id: 1117, association: 'fms', acceptsNewMembers: true, name: 'VD01 - Moto-Club Aigle' },
  { id: 1118, association: 'fms', acceptsNewMembers: true, name: 'VD04 - Moto Club Cossonay' },
  { id: 1119, association: 'fms', acceptsNewMembers: true, name: 'VD05 - Moto Club Lac de Joux' },
  { id: 1120, association: 'fms', acceptsNewMembers: true, name: 'VD09 - AMC Puidoux-Chexbres' },
  { id: 1121, association: 'fms', acceptsNewMembers: true, name: 'VD12 - Moto Club Vevey' },
  { id: 1122, association: 'fms', acceptsNewMembers: true, name: 'VD21 - MCC des Combremonts' },
  { id: 1123, association: 'fms', acceptsNewMembers: true, name: 'VD22 - AMC Payerne' },
  { id: 1124, association: 'fms', acceptsNewMembers: true, name: 'VD30 - Racing MC Suisse' },
  { id: 1125, association: 'fms', acceptsNewMembers: true, name: 'VD34 - MC Le Talent' },
  { id: 1126, association: 'fms', acceptsNewMembers: true, name: 'VD41 - AMA' },
  { id: 1127, association: 'fms', acceptsNewMembers: true, name: 'VD42 - Trial Club Passepartout Moudon' },
  { id: 1128, association: 'fms', acceptsNewMembers: false, name: 'VD43 - Moto Club Broye' },
  { id: 1129, association: 'fms', acceptsNewMembers: true, name: 'VD44 - Moto Club La Côte' },
  { id: 1130, association: 'fms', acceptsNewMembers: true, name: 'VS01 - Clubs FMV' },
  { id: 1131, association: 'fms', acceptsNewMembers: true, name: 'VS03 - MC Bagnes' },
  { id: 1132, association: 'fms', acceptsNewMembers: true, name: 'VS04 - MC Les Grenades Nendaz' },
  { id: 1133, association: 'fms', acceptsNewMembers: true, name: 'VS05 - MC Chamoson' },
  { id: 1134, association: 'fms', acceptsNewMembers: true, name: 'VS07 - MC Navizance-Chippis' },
  { id: 1135, association: 'fms', acceptsNewMembers: true, name: 'VS08 - AMC La Poya' },
  { id: 1136, association: 'fms', acceptsNewMembers: true, name: 'VS11 - MC Fully' },
  { id: 1137, association: 'fms', acceptsNewMembers: true, name: "VS12 - MC D'Anges Heureux Grône" },
  { id: 1138, association: 'fms', acceptsNewMembers: true, name: 'VS13 - CM Martigny' },
  { id: 1139, association: 'fms', acceptsNewMembers: true, name: 'VS16 - MC Illgraben Susten' },
  { id: 1140, association: 'fms', acceptsNewMembers: true, name: 'VS17 - La Moto Verte Monthey' },
  { id: 1141, association: 'fms', acceptsNewMembers: true, name: 'VS18 - AMC Le Muguet Muraz' },
  { id: 1142, association: 'fms', acceptsNewMembers: true, name: 'VS19 - MC Orsières' },
  { id: 1143, association: 'fms', acceptsNewMembers: true, name: 'VS21 - MC La Lienne St-Léonard' },
  { id: 1144, association: 'fms', acceptsNewMembers: true, name: 'VS24 - AMC Sanetsch' },
  { id: 1145, association: 'fms', acceptsNewMembers: true, name: 'VS25 - MC Le Soleil Sierre' },
  { id: 1146, association: 'fms', acceptsNewMembers: true, name: 'VS26 - MC Simplon' },
  { id: 1147, association: 'fms', acceptsNewMembers: true, name: 'VS27 - MC Le Rhône Sion' },
  { id: 1148, association: 'fms', acceptsNewMembers: true, name: 'VS28 - MC Troistorrents' },
  { id: 1149, association: 'fms', acceptsNewMembers: true, name: "VS29 - AMC Val d'Illiez-Champéry" },
  { id: 1150, association: 'fms', acceptsNewMembers: true, name: 'VS36 - MC Les Impecs Boys Vionnaz' },
  { id: 1151, association: 'fms', acceptsNewMembers: true, name: 'VS38 - Association Snow Race Anzère' },
  { id: 1152, association: 'fms', acceptsNewMembers: true, name: 'VS39 - MC Les Faucons' },
  { id: 1153, association: 'fms', acceptsNewMembers: true, name: 'WA01 - MC Drifter' },
  { id: 1154, association: 'fms', acceptsNewMembers: true, name: 'ZH01 - MSC Sihltal-Adliswil' },
  { id: 1155, association: 'fms', acceptsNewMembers: true, name: 'ZH06 - MSC Zueri, Zürich' },
  { id: 1156, association: 'fms', acceptsNewMembers: true, name: 'ZH14 - TWN Club Zürich' },
  { id: 1157, association: 'fms', acceptsNewMembers: true, name: 'ZH20 - 4Takt MSC Zürich' },
  { id: 1158, association: 'fms', acceptsNewMembers: true, name: 'ZH25 - Geländesportclub Zürich' },
  { id: 1159, association: 'fms', acceptsNewMembers: false, name: 'ZH26 - Motocross Club Neftenbach (MCCN)' },
  { id: 999, association: 'sam', acceptsNewMembers: false, name: '- Keine SAM Sektion / Aucun section SAM / No SAM section' },
  { id: 132, association: 'sam', acceptsNewMembers: true, name: 'AG - MC Hot Gum' },
  { id: 186, association: 'sam', acceptsNewMembers: true, name: 'AG - MC Wohlen' },
  { id: 157, association: 'sam', acceptsNewMembers: true, name: 'AI/AR - Rehetobel' },
  { id: 118, association: 'sam', acceptsNewMembers: true, name: 'BE - Emmental' },
  { id: 208, association: 'sam', acceptsNewMembers: true, name: 'BE - Racing Gang' },
  { id: 206, association: 'sam', acceptsNewMembers: true, name: 'BE - Simmental' },
  { id: 203, association: 'sam', acceptsNewMembers: true, name: 'GR - Graubünden' },
  { id: 212, association: 'sam', acceptsNewMembers: false, name: 'GR - MX Surselva' },
  { id: 124, association: 'sam', acceptsNewMembers: true, name: 'JU - MC Ederswiler' },
  { id: 227, association: 'sam', acceptsNewMembers: true, name: 'LU - Track Days Luzern' },
  { id: 101, association: 'sam', acceptsNewMembers: true, name: 'SG - Altstätten' },
  { id: 119, association: 'sam', acceptsNewMembers: true, name: 'SG - AMC Wil und Umgebung' },
  { id: 111, association: 'sam', acceptsNewMembers: true, name: 'SG - an der Hulftegg' },
  { id: 140, association: 'sam', acceptsNewMembers: true, name: 'SG - Burgau B/Flawil' },
  { id: 126, association: 'sam', acceptsNewMembers: true, name: 'SG - Degersheim' },
  { id: 151, association: 'sam', acceptsNewMembers: true, name: 'SG - Neckertal-Brunnader' },
  { id: 226, association: 'sam', acceptsNewMembers: true, name: 'SG - Paddys-Races-Days' },
  { id: 184, association: 'sam', acceptsNewMembers: true, name: 'SG - Speer Weesen Glarnerland' },
  { id: 163, association: 'sam', acceptsNewMembers: true, name: 'SG - St. Gallen' },
  { id: 148, association: 'sam', acceptsNewMembers: true, name: 'SH - Randen Schleitheim' },
  { id: 141, association: 'sam', acceptsNewMembers: false, name: 'SO - Mitteland Olten' },
  { id: 225, association: 'sam', acceptsNewMembers: true, name: 'SO - MXRS' },
  { id: 147, association: 'sam', acceptsNewMembers: true, name: 'SZ - MSC Innerschweiz' },
  { id: 103, association: 'sam', acceptsNewMembers: true, name: 'SZ - Obersee Altendorf' },
  { id: 153, association: 'sam', acceptsNewMembers: true, name: 'TG - AMCO Oberthurgau' },
  { id: 121, association: 'sam', acceptsNewMembers: true, name: 'TG - Bischofszell' },
  { id: 144, association: 'sam', acceptsNewMembers: true, name: 'TG - Lauchetal-Lommis' },
  { id: 215, association: 'sam', acceptsNewMembers: true, name: 'TG - MC Mittelthurgau' },
  { id: 158, association: 'sam', acceptsNewMembers: true, name: 'TG - MRSV Frauenfeld' },
  { id: 160, association: 'sam', acceptsNewMembers: true, name: 'TG - MSC Weinfelden' },
  { id: 217, association: 'sam', acceptsNewMembers: true, name: 'VD - ASPB' },
  { id: 220, association: 'sam', acceptsNewMembers: true, name: 'ZG - Minibike-Racing Switzerland' },
  { id: 224, association: 'sam', acceptsNewMembers: true, name: 'ZG - Swiss Legend Cars' },
  { id: 222, association: 'sam', acceptsNewMembers: true, name: 'ZH - ACE' },
  { id: 104, association: 'sam', acceptsNewMembers: true, name: 'ZH - am Albis' },
  { id: 142, association: 'sam', acceptsNewMembers: true, name: 'ZH - AMC Swissair' },
  { id: 110, association: 'sam', acceptsNewMembers: true, name: 'ZH - Andelfingen' },
  { id: 219, association: 'sam', acceptsNewMembers: true, name: 'ZH - CCZO Hinwil' },
  { id: 182, association: 'sam', acceptsNewMembers: true, name: 'ZH - MC Winterthur' },
  { id: 211, association: 'sam', acceptsNewMembers: true, name: 'ZH - MCC Neftenbach' },
  { id: 223, association: 'sam', acceptsNewMembers: true, name: 'ZH - Moto Gymkhana Swiss' },
  { id: 175, association: 'sam', acceptsNewMembers: true, name: 'ZH - MSG Gutenswil' },
  { id: 150, association: 'sam', acceptsNewMembers: true, name: 'ZH - NSU Zuercher Oberland' },
  { id: 204, association: 'sam', acceptsNewMembers: true, name: 'ZH - Swiss Performance' },
  { id: 216, association: 'sam', acceptsNewMembers: true, name: 'ZH - TWN Club Zürich, nur Trialfahrer' },
  { id: 180, association: 'sam', acceptsNewMembers: true, name: 'ZH - Wald' },
  { id: 190, association: 'sam', acceptsNewMembers: true, name: 'ZH - Wila' },
] as const

export const rawSections: readonly Section[] = constSections
