import { isAfter, isBefore, parseISO } from 'date-fns'
import { sum } from 'lodash'
import { categoryByIdRequired, categoryOfAssociationRequired } from 'shared/data/categories-service'
import {
  Documents,
  LicenseDraftWithDocuments,
  LicenseFormData,
  LicenseLineItem,
  LineItem,
  TransponderLineItem,
} from 'shared/db/db'
import { t } from 'shared/i18n/current'
import {
  LicenseCategoryContext,
  licenseCategoryContexts,
  licenseCategoryContextsFromLicenses,
} from 'shared/license/license-draft-categories'
import {
  mainLicenseName,
  additionalLicenseName,
  discountName,
  surchargeName,
} from 'shared/licenses-booking-names'
import {
  AssociationCategory,
  associationSpecificDetails,
  Category,
  PriceByAge,
  PriceContext,
} from 'shared/models/category'
import { transponderOptionRequired } from 'shared/models/transponder'
import { groupByLiteral, isAtLeastLength, strictMinBy } from 'shared/utils/array'
import { isSameOrAfter, isSameOrBefore, pFormatDateDe } from 'shared/utils/date'

export function licenseCategoryLineItemsForLicenseForm(
  licenseData: LicenseFormData
): LicenseLineItem[] | undefined {
  if (!licenseData.documents?.personalData?.birthdate) return undefined
  return licenseCategoryLineItems({
    processedAt: licenseData.licenseDrafts?.summary?.processedAt || new Date().toISOString(),
    documents: licenseData.documents,
    licenseCategories: licenseCategoryContexts(licenseData.licenseDrafts, licenseData.approvedLicenses),
    orderedTransponderItems: [],
  })
}

export function licenseCategoryLineItemsByLicenses(
  licenses: LicenseDraftWithDocuments[],
  documents: Documents,
  orderedTransponderItems: TransponderLineItem[]
) {
  return licenseCategoryLineItems({
    processedAt: licenses[0]?.draft.summary?.processedAt,
    documents,
    licenseCategories: licenseCategoryContextsFromLicenses(licenses),
    orderedTransponderItems,
  })
}

export function totalLineItem(items: LineItem[]) {
  return {
    name: 'Total',
    price: sum(items.map(({ price }) => price)),
  }
}

function licenseCategoryLineItems(props: {
  documents: Documents | undefined
  processedAt: string | undefined
  licenseCategories: LicenseCategoryContext[]
  orderedTransponderItems: TransponderLineItem[]
}): LicenseLineItem[] {
  const grouped = groupByLiteral(
    props.licenseCategories,
    (licenseCategory) =>
      `${licenseCategory.association}-${categoryByIdRequired(licenseCategory.category).year}`
  )
  return [
    ...Object.values(grouped).flatMap((licenseCategories) =>
      licenseCategoryLineItemsByAssociation({
        ...props,
        orderedTransponderItems: [],
        licenseCategories,
      })
    ),
    ...transponderLineItems(props),
  ]
}

function licenseCategoryLineItemsByAssociation(props: {
  documents: Documents | undefined
  processedAt: string | undefined
  licenseCategories: LicenseCategoryContext[]
  orderedTransponderItems: TransponderLineItem[]
}): LicenseLineItem[] {
  const { documents, processedAt, licenseCategories, orderedTransponderItems } = props

  const processedAtDate = processedAt ? parseISO(processedAt) : new Date()
  const birthdate = documents?.personalData?.birthdate
  if (!birthdate) throw new Error('No birthdate found')

  if (licenseCategories.length === 0) return []

  const oldestAndMostExpensiveLicense = calculateOldestMostExpensiveLicense(licenseCategories, birthdate)

  const additionalCategories = licenseCategories.filter(
    (category) => category !== oldestAndMostExpensiveLicense
  )
  const mostExpensiveCategory = categoryOfAssociationRequired(
    oldestAndMostExpensiveLicense.category,
    oldestAndMostExpensiveLicense.association
  )
  const association = oldestAndMostExpensiveLicense.association

  return [
    {
      type: 'categoryLineItem' as const,
      subtype: 'mainLicense' as const,
      reverse: false,
      categoryId: oldestAndMostExpensiveLicense.category,
      name: mainLicenseName(mostExpensiveCategory),
      price: categoryPrice(mostExpensiveCategory, {
        birthdate,
        association: oldestAndMostExpensiveLicense.association,
        licenseType: oldestAndMostExpensiveLicense.licenseType,
      }),
      association: oldestAndMostExpensiveLicense.association,
    },
    ...additionalCategories.map((licenseCategory) => {
      const category = categoryByIdRequired(licenseCategory.category)
      return {
        type: 'categoryLineItem' as const,
        subtype: 'additionalLicense' as const,
        reverse: false,
        categoryId: category.id,
        name: additionalLicenseName(categoryOfAssociationRequired(category.id, association)),
        price: additionalPrice(category, {
          birthdate,
          association: licenseCategory.association,
          licenseType: licenseCategory.licenseType,
        }),
        association: licenseCategory.association,
      }
    }),
    ...orderedTransponderItems,
    ...(hasDiscount(mostExpensiveCategory, processedAtDate)
      ? [
          {
            type: 'categoryLineItem' as const,
            subtype: 'licenseDiscount' as const,
            reverse: false,
            name: discountName(mostExpensiveCategory),
            price: -mostExpensiveCategory.discount,
            categoryId: oldestAndMostExpensiveLicense.category,
            association,
          },
        ]
      : []),
    ...(hasSurcharge(mostExpensiveCategory, processedAtDate)
      ? [
          {
            type: 'categoryLineItem' as const,
            subtype: 'licenseSurcharge' as const,
            reverse: false,
            name: surchargeName(mostExpensiveCategory),
            price: mostExpensiveCategory.surcharge,
            categoryId: oldestAndMostExpensiveLicense.category,
            association,
          },
        ]
      : []),
  ]
}

function calculateOldestMostExpensiveLicense(
  allLicenseCategories: LicenseCategoryContext[],
  birthdate: string
) {
  const preferred = allLicenseCategories.filter(preferAsMainLicense)
  const potentialMainLicenseCategories = preferred.length ? preferred : allLicenseCategories

  const mostExpensivePrice = Math.max(
    ...potentialMainLicenseCategories.map((licenseCategory) =>
      licenseCategoryPrice(licenseCategory, birthdate)
    )
  )

  const mostExpensiveLicenses = potentialMainLicenseCategories.filter(
    (licenseCategory) => licenseCategoryPrice(licenseCategory, birthdate) === mostExpensivePrice
  )

  return oldestLicense(mostExpensiveLicenses)
}

function preferAsMainLicense(category: LicenseCategoryContext) {
  return !categoryOfAssociationRequired(category.category, category.association)
    .preferAsAdditionalLicense
}

function licenseCategoryPrice(licenseCategory: LicenseCategoryContext, birthdate: string) {
  return categoryPrice(categoryByIdRequired(licenseCategory.category), {
    birthdate,
    association: licenseCategory.association,
    licenseType: licenseCategory.licenseType,
  })
}

function oldestLicense(licenses: LicenseCategoryContext[]): LicenseCategoryContext {
  const approved = licenses.filter((license) => license.approvedAt)
  if (!isAtLeastLength(licenses, 1)) throw new Error('No licenses found')

  return isAtLeastLength(approved, 1)
    ? strictMinBy(approved, (license) => license.approvedAt)
    : strictMinBy(licenses, (license) => categoryByIdRequired(license.category).commonName)
}

function transponderLineItems(props: {
  documents: Documents | undefined
  orderedTransponderItems: TransponderLineItem[]
}) {
  const { documents, orderedTransponderItems } = props
  const orderedIDs = orderedTransponderItems.map((item) => item.transponderId)
  const newIDs = orderedTransponders(documents).filter((id) => !orderedIDs.includes(id))
  return [
    ...newIDs.map<TransponderLineItem>((transponder) => ({
      type: 'transponderLineItem' as const,
      subtype: 'transponder' as const,
      transponderType: transponderOptionRequired(transponder).type,
      transponderId: transponderOptionRequired(transponder).id,
      name: `Transponder: ${transponderOptionRequired(transponder).shortName()}`,
      price: transponderOptionRequired(transponder).price,
      association: transponderOptionRequired(transponder).association,
      reverse: false,
    })),
    ...orderedTransponderItems,
  ]
}

function orderedTransponders(documents: Documents | undefined) {
  return documents?.transponder?.orderedTransponders || []
}

export function reverseBooking(item: LicenseLineItem) {
  return { ...item, name: `Storniert: ${item.name}`, price: -item.price, reverse: true }
}

export function categoryPriceIncludingAdditionGivenBase(category: AssociationCategory, base: number) {
  const discount = hasDiscount(category, new Date()) ? category.discount : 0
  const surcharge = hasSurcharge(category, new Date()) ? category.surcharge : 0
  const total = base - discount + surcharge
  return { base, discount, surcharge, total }
}

function additionalPrice(category: Category, context: PriceContext) {
  const associationCategory = categoryOfAssociationRequired(category.id, context.association)
  const normalPrice = categoryPrice(associationCategory, context)
  const priceAdditionalLicense = associationCategory.priceAdditionalLicense
  return normalPrice === 0 ? priceAdditionalLicense : Math.min(normalPrice, priceAdditionalLicense)
}

export function categoryPrice(category: Category, context: PriceContext) {
  const categoryDetails = associationSpecificDetails(category, context.association)
  const pricesByLicenseType = categoryDetails.prices.filter(
    (price) => price.licenseType === context.licenseType
  )
  return categoryPriceByAge(pricesByLicenseType, context)
}

export function categoryPriceByAge(
  prices: PriceByAge[] | Readonly<PriceByAge[]>,
  { birthdate }: { birthdate: string }
) {
  const price = prices.find(
    (price) =>
      (!price.from || isSameOrBefore(birthdate, price.from)) &&
      (!price.to || isSameOrAfter(birthdate, price.to))
  )

  if (!price) throw new Error(`Invalid category prices ${JSON.stringify(prices)}`)

  return price.value
}

export function priceRangeText({ from, to }: { from: string | undefined; to: string | undefined }) {
  const [formattedFrom, formattedTo] = [from, to].map(pFormatDateDe)
  return from && to
    ? t().financials.priceRangeTextBetween(formattedFrom, formattedTo)
    : from
    ? t().financials.priceRangeTextOlderThan(formattedFrom)
    : to
    ? t().financials.priceRangeTextYoungerThan(formattedTo)
    : ''
}

function hasDiscount(category: AssociationCategory, date: Date) {
  return category.discount > 0 && isBefore(date, parseISO(category.discountUntil))
}

function hasSurcharge(category: AssociationCategory, date: Date) {
  return category.surcharge > 0 && isAfter(date, parseISO(category.surchargeAfter))
}
