import { sortBy, uniq } from 'lodash'
import { useAllDocuments } from 'app/db/db-contexts/documents-context'
import {
  UseWithObj,
  combineFire2,
  combineFire3,
  useFire,
  le,
  combineFire4,
  useRecords,
  combineFire6,
} from 'app/db/db-hooks/db-hook-helpers'
import { orEmptyArray, orEmptyObject, emptyObject } from 'app/db/db-hooks/empty-hook-helpers'
import {
  useAllBalancesWithoutUsers,
  useAllLicenseBookingsByUserOfYear,
  useAllPaymentsByRider,
  useAllPaymentsSorted,
  useAllTransactionsWithoutUsers,
  useAssociationPaymentsFlat,
} from 'app/db/db-hooks/financial-db-hooks'
import type { AdminContext } from 'app/themes/admin-context'
import { UserState, useUserContext } from 'app/themes/user-context'
import { useLicenseYear } from 'app/themes/year-context'
import {
  categoryById,
  categoryByIdFromString,
  categoryByIdRequired,
  categoryOfAssociation,
  firstValidLicenseType,
  fixedLicenseType,
} from 'shared/data/categories-service'
import { nextLicenseYear, oldLicenseYear } from 'shared/data/license-config'
import { LicenseTasksOverview } from 'shared/data/license-tasks-overview'
import { Year } from 'shared/data/license-year'
import { displayIssuedNumber } from 'shared/data/licenses-service'
import { registeredViaTrustedAssociation } from 'shared/db/association'
import { DayCategory, DayCategoryID } from 'shared/db/day-category'
import {
  ApprovedLicense,
  Documents,
  LicenseDrafts,
  LicenseFormData,
  UserData,
  UserQuery as User,
  LicenseWithContext,
  UserQuery,
  ApprovedLicenseWithContext,
  InscriptionWithContextAndSportEvent,
  DocumentsWithUid,
} from 'shared/db/db'
import { MigrationState } from 'shared/db/db-migrations'
import { licenseDraftsByCategory, documentsWithLicenses } from 'shared/db/license-drafts'
import {
  searchableDocumentsFoundByQueryPart,
  draftFoundByQueryPart,
  extractQueryPart,
  queryParts,
  searchDocumentsMap,
  searchInscriptions,
} from 'shared/db/search'
import { SportEventDate, SportEventId } from 'shared/db/sport-event-id'
import { sortTransactions } from 'shared/db/transactions-service'
import { UserId } from 'shared/db/user-id'
import { inscriptionIssuedOrPreferredNumber } from 'shared/inscription/inscription-categories-service'
import { migrateInscribed } from 'shared/inscription/inscription-status'
import { inscriptionStatus } from 'shared/inscription/inscription-status-service'
import { licenseTasks } from 'shared/license/license-tasks'
import { needsHealthCheck } from 'shared/license/needs-health-check'
import { bookingContexts } from 'shared/licenses-bookings'
import { AssociationID, todoMigrateAssociation } from 'shared/models/associations'
import { Bike } from 'shared/models/bike'
import { Category, CategoryId } from 'shared/models/category'
import { DriversLicense } from 'shared/models/DriversLicense'
import { Emergency } from 'shared/models/emergency'
import { HealthCheck } from 'shared/models/health-check'
import { Insurance } from 'shared/models/insurance'
import {
  nameWithPlace,
  notFoundPlaceholder,
  PersonalData,
  reverseFullName,
  sortName,
} from 'shared/models/personal-data'
import { Photo } from 'shared/models/photo'
import { transponderOption, Transponder } from 'shared/models/transponder'
import {
  Inscription,
  PublicInscription,
  SportEvent,
  InscriptionV1,
  isDayInscription,
  isInscribedInscription,
  isLicenseInscription,
} from 'shared/sport-events/sport-events'
import { isOnlineSportEvent } from 'shared/sport-events/sport-events-service'
import { groupByString, indexByString, truthy } from 'shared/utils/array'
import { parseInt10 } from 'shared/utils/number'
import { markUndefined } from 'shared/utils/tsc'

export function useExecutedMigrations() {
  return useRecords<MigrationState>('migrations/executed')
}

export function useAllBalances(sortByProp: 'balance' | 'name') {
  const { data, ...rest } = combineFire2(
    useAllBalancesWithoutUsers(),
    useAllDocuments(),
    (balances, documents) =>
      sortBy(
        balances.map(({ uid, balance }) => ({ uid, documents: markUndefined(documents[uid]), balance })),
        (o) =>
          sortByProp === 'balance' ? -Math.abs(o.balance) : reverseFullName(o.documents?.personalData)
      )
  )

  return { data, ...rest }
}

export function useAllManualPaymentsWithName() {
  const { data, ...rest } = combineFire2(
    useAllPaymentsSorted(),
    useAllDocuments(),
    (transactions, documents) =>
      transactions
        .map((transaction) => (transaction.type === 'manualPayment' ? transaction : undefined))
        .filter(truthy)
        .map((transaction) => ({
          transaction,
          uidName: nameWithPlace(documents[transaction.uid]?.personalData),
          byUidName: nameWithPlace(documents[transaction.byUid]?.personalData),
        }))
  )

  return { data, ...rest }
}

export function useAllTransactionsMissingUser() {
  const { data, ...rest } = useAllTransactions()
  return {
    data: data
      .filter((t) => t.transaction.type !== 'associationPayment')
      .filter((t) => t.uidName === notFoundPlaceholder),
    ...rest,
  }
}

export function useAllTransactions() {
  const { data, ...rest } = combineFire3(
    useAllTransactionsWithoutUsers(),
    useAllDocuments(),
    useAssociationPaymentsFlat(),
    (transactions, documents, payments) =>
      [...transactions, ...payments].map((transaction) => {
        const uid =
          transaction.type === 'associationPayment' ? transaction.requestedByUid : transaction.uid
        const byUid =
          transaction.type === 'associationPayment'
            ? transaction.paidByUid
            : transaction.type === 'payment'
            ? ''
            : transaction.byUid
        return {
          transaction:
            transaction.type === 'associationPayment'
              ? { currentTotal: 0, ...transaction }
              : transaction,
          uidName: nameWithPlace(documents[uid]?.personalData),
          byUidName:
            transaction.type === 'payment'
              ? 'Upload Bankzahlungen'
              : byUid
              ? nameWithPlace(documents[byUid]?.personalData)
              : '-',
        }
      })
  )

  return { data, ...rest }
}

export function useLicenseIssued(user: User, year: Year) {
  return useLicenseTasksOverview(user, year).data?.allDone
}

export function useLicenseTasksOverview(
  user: User,
  year: Year
): UseWithObj<LicenseTasksOverview | undefined> {
  const { data: dashboardData, ...rest } = useDashboardData(user, year)

  if (rest.loadingOrError) return { data: undefined, ...rest }

  const approvedLicenses = dashboardData.licenseData.approvedLicenses.filter(
    (license) => categoryById(license.categoryId)?.year === nextLicenseYear
  )
  const licensePaid = approvedLicenses.length === 0 || approvedLicenses.every((license) => license.paid)
  const categories = [
    ...Object.values(dashboardData.licenseData.licenseDrafts?.categoryDetails || {}).map((value) =>
      categoryOfAssociation(value.categoryId, todoMigrateAssociation(value.licenseAssociation))
    ),
    ...approvedLicenses.map((license) =>
      categoryOfAssociation(license.categoryId, todoMigrateAssociation(license.licenseAssociation))
    ),
  ].filter(truthy)
  const currentYearCategories = categories.filter((category) => category.year === nextLicenseYear)
  const latestLicenseYear = Math.max(...categories.map((category) => category.year)) as Year

  return {
    data: licenseTasks({
      submittedLicense: !!dashboardData.licenseData.licenseDrafts?.summary?.processed,
      documents: dashboardData.licenseData.documents,
      licensePaid,
      needsHealthCheck: dashboardData.needsHealthCheck,
      needsInsurance: dashboardData.needsInsurance,
      needsEmergency: dashboardData.needsEmergency,
      registeredViaTrustedAssociation:
        approvedLicenses.length > 0 &&
        approvedLicenses.every((license) => registeredViaTrustedAssociation(license)),
      year: latestLicenseYear,
      isSam: currentYearCategories.some((category) => category.association === 'sam'),
      needsMembership: dashboardData.needsMembership,
    }),
    ...rest,
  }
}

export function useFullDashboardData(user: User) {
  return combineFire3(
    useDashboardData(user, nextLicenseYear),
    useDashboardData(user, oldLicenseYear),
    useInscriptionsByRider(user),
    (next, old, inscriptions) => ({
      licenseData: {
        documents: next.licenseData.documents,
        licenseDrafts: {
          categories: [
            ...orEmptyArray(next.licenseData.licenseDrafts?.categories),
            ...orEmptyArray(old.licenseData.licenseDrafts?.categories),
          ],
          categoryDetails: {
            ...next.licenseData.licenseDrafts?.categoryDetails,
            ...old.licenseData.licenseDrafts?.categoryDetails,
          },
          summary: next.licenseData.licenseDrafts?.summary,
        },
        approvedLicenses: [...next.licenseData.approvedLicenses, ...old.licenseData.approvedLicenses],
      },
      categoryDetails: [...next.categoryDetails, ...old.categoryDetails],
      needsHealthCheck: next.needsHealthCheck || old.needsHealthCheck,
      needsInsurance: next.needsInsurance || old.needsInsurance,
      needsEmergency: next.needsEmergency || old.needsEmergency,
      inscriptions,
    })
  )
}

function useDashboardData(user: User, year: Year) {
  return combineFire6(
    useLicenseData(user, year),
    useCategoryDetails(user, year),
    useNeedsHealthCheck(user, year),
    useNeedsInsurance(user, year),
    useNeedsEmergency(user, year),
    useNeedsMembership(user, year),
    (
      licenseData,
      categoryDetails,
      needsHealthCheck,
      needsInsurance,
      needsEmergency,
      needsMembership
    ) => ({
      licenseData,
      categoryDetails,
      needsHealthCheck,
      needsInsurance,
      needsEmergency,
      needsMembership,
    })
  )
}

export function useNeedsHealthCheck(user: User, year: Year) {
  const { data, ...rest } = combineFire2(
    useSelectedCategoriesWithLicenseDetails(user, year),
    usePersonalData(user),
    (categories, personalData) =>
      categories.map((category) => ({ ...category, birthdate: personalData?.birthdate || '' }))
  )
  return {
    data: rest.loadingOrError || data.filter((row) => row.birthdate).some(needsHealthCheck),
    ...rest,
  }
}

export function useNeedsInsurance(user: User, year: Year) {
  const { data, ...rest } = useSelectedCategories(user, year)
  return { data: rest.loadingOrError || data.some(({ needsInsurance }) => needsInsurance), ...rest }
}

export function useNeedsEmergency(user: User, year: Year) {
  const { data, ...rest } = useSelectedCategories(user, year)
  return { data: rest.loadingOrError || data.some(({ needsEmergency }) => needsEmergency), ...rest }
}

export function useNeedsMembership(user: User, year: Year) {
  const { data, ...rest } = useSelectedCategories(user, year)
  return { data: rest.loadingOrError || data.some(({ needsMembership }) => needsMembership), ...rest }
}

export function useNeededTransponders(user: User, year: Year) {
  const { data, ...rest } = useSelectedCategories(user, year)
  return {
    data: rest.loadingOrError ? [] : data.filter(({ transponders }) => transponders.length),
    ...rest,
  }
}

function useSelectedCategories(user: User, year: Year) {
  const { data, ...rest } = useSelectedCategoriesWithLicenseDetails(user, year)
  if (rest.loadingOrError) return { data: [], ...rest }

  return { data: data.map(({ category }) => category), ...rest }
}

function useSelectedCategoriesWithLicenseDetails(user: User, year: Year) {
  const { data, ...rest } = useSelectedLicenseDetails(user, year)
  if (rest.loadingOrError) return { data: [], ...rest }

  const selectedCategories = data
    .map(({ category, association, licenseType }) => {
      const cat = categoryOfAssociation(category, todoMigrateAssociation(association))
      return cat ? { category: cat, licenseType: licenseType || firstValidLicenseType(cat) } : undefined
    })
    .filter(truthy)
  return { data: selectedCategories, ...rest }
}

function useSelectedLicenseDetails(user: User, year: Year) {
  const { data, ...rest } = combineFire2(
    useLicenseData(user, year),
    useCategoryDetails(user, year),
    (licenseData, categoryDetails) => ({ licenseData, categoryDetails })
  )
  if (rest.loadingOrError) return { data: [], ...rest }

  const approvedLicenses = data.licenseData.approvedLicenses
  const categoriesWithLicenseDetails = [
    ...approvedLicenses
      .map((it) => fixedLicenseType(it))
      .map(({ categoryId, licenseAssociation, licenseType }) => ({
        category: categoryId,
        association: licenseAssociation,
        licenseType,
      })),
    ...data.categoryDetails.map(({ category, licenseAssociation: association, licenseType }) => ({
      category: category.id,
      association,
      licenseType,
    })),
  ]
  return { data: categoriesWithLicenseDetails, ...rest }
}

function useCategoryDetails(user: User, year: Year) {
  const { data: licenseData, ...rest } = useLicenseData(user, year)
  return { data: rest.loadingOrError ? [] : enhancedCategoryDetails(licenseData), ...rest }
}

function enhancedCategoryDetails(licenseData: LicenseFormData) {
  return orEmptyArray(licenseData.licenseDrafts?.categories)
    .map((categoryId) => {
      const detail = fixedLicenseType(licenseData.licenseDrafts?.categoryDetails?.[categoryId])
      const category = detail && categoryById(detail.categoryId)
      return detail && category
        ? {
            category,
            ...fixedLicenseType({
              ...detail,
              licenseAssociation: todoMigrateAssociation(detail.licenseAssociation),
            }),
          }
        : undefined
    })
    .filter(truthy)
}

export function useLicenseBookingsByUsers(
  query: string,
  admin: UserQuery,
  association: AssociationID | undefined | false
) {
  const licenseYear = useLicenseYear()
  const { newQuery, includedPart: filterForDiscrepancies } = extractQueryPart(query, discrepancies)
  return combineFire3(
    useSearchLicenses('', true),
    useAllDocuments(),
    useAllLicenseBookingsByUserOfYear(licenseYear),
    (licenses, documents, bookings) => {
      if (association === false) return []

      const licensesWithBookings = uniqUids(licenses, bookings)
        .map((uid) => {
          const firstLicense = licenses.find((license) => license.license.userId === uid)
          return {
            uid,
            // TODO: later: find out when documents[uid] can be undefined
            documents: documents[uid] ? documents[uid] : undefined,
            licenses: orEmptyArray(firstLicense?.all),
            licenseBookings: sortTransactions(orEmptyArray(bookings[uid])),
          }
        })
        .filter(truthy)

      const parts = queryParts(newQuery)
      const filtered = licensesWithBookings.filter((booking) =>
        parts.every(
          (part) =>
            searchableDocumentsFoundByQueryPart({ ...booking.documents, uid: booking.uid }, part) ||
            booking.licenses.some((license) => draftFoundByQueryPart(license, [], part))
        )
      )

      const sorted = sortBy(filtered, ({ uid, documents }) => sortName(uid, documents?.personalData))
      const contexts = bookingContexts(sorted, admin, association)

      const contextsWithBookings = contexts.filter(
        (row) =>
          row.bookingContext.potentialItems.length > 0 || row.bookingContext.bookedItems.length > 0
      )

      return contextsWithBookings.filter(
        (context) =>
          !filterForDiscrepancies ||
          context.bookingContext.state === 'no-booked-items' ||
          context.bookingContext.state === 'conflicting-items'
      )
    }
  )
}

export const discrepancies = 'Unstimmigkeiten'

function uniqUids(licenses: LicenseWithContext[], bookingsByUser: Record<string, unknown>) {
  return uniq([...licenses.map(({ license: { userId } }) => userId), ...Object.keys(bookingsByUser)])
}

export function usePersonalDataForEdit(user: User, year: Year) {
  return combineFire2(
    useHasSubmittedLicense(user, year),
    usePersonalData(user),
    (hasSubmittedLicense, personalData) => ({ hasSubmittedLicense, personalData })
  )
}

export function useHasSubmittedLicense(user: User, year: Year) {
  const { data: licenseData, ...rest } = useLicenseData(user, year)
  return { ...rest, data: !!licenseData?.licenseDrafts?.summary?.processed }
}

export function useLicenseData(user: User, year: Year): UseWithObj<LicenseFormData> {
  return combineFire3(
    useDocuments(user),
    useLicenseDrafts(user, year),
    useApprovedLicenses(user, year),
    (documents, licenseDrafts, approved) => ({
      documents,
      licenseDrafts,
      approvedLicenses: Object.values(approved || {}),
    })
  )
}

export function useNewTransponders() {
  const userContext = useUserContext()
  const result = useAllDocumentsFlat()
  return {
    ...result,
    data: orEmptyArray(result.data)
      .map((documents) => ({
        documents,
        transponders: userOrderedTransponderFromAssociation(userContext, documents),
      }))
      .filter(({ transponders }) => transponders.length > 0),
  }
}

function userOrderedTransponderFromAssociation(userContext: UserState, documents: DocumentsWithUid) {
  return orEmptyArray(
    documents.transponder?.orderedTransponders
      ?.map((id) => transponderOption(id))
      .filter(truthy)
      .filter((option) => userContext.canViewAssociation(option.association))
  )
}

export function useSearchApprovedLicensesWithContext(
  adminContext: AdminContext,
  query: string,
  calculateFieldsAllOthersAndConflicts: boolean
) {
  const { data, ...rest } = useSearchApprovedLicenses(query, calculateFieldsAllOthersAndConflicts)
  return {
    ...rest,
    data: data.filter((license) => {
      const category = categoryByIdRequired(license.license.approved.categoryId)
      return (
        category.year === adminContext.year &&
        category.associations.some((association) => adminContext.matchesAssociation(association))
      )
    }),
  }
}

export function useSearchApprovedLicenses(query: string, calculateFieldsAllOthersAndConflicts: boolean) {
  const licenses = useSearchLicenses(query, calculateFieldsAllOthersAndConflicts)
  const approvedLicenses = licenses.data
    .map<ApprovedLicenseWithContext | undefined>((context) =>
      context.license.type === 'approved' ? { ...context, license: context.license } : undefined
    )
    .filter(truthy)
  const data: ApprovedLicenseWithContext[] = sortBy(
    approvedLicenses.map((context) => ({
      ...context,
      sortFirst: context.license.approved.categoryId,
      sortSecond: displayIssuedNumber(context.license.approved),
    })),
    ['sortFirst', 'sortSecond']
  )
  return { ...licenses, data }
}

export function useSearchLicenseDrafts(query: string, calculateFieldsAllOthersAndConflicts: boolean) {
  const { data, ...rest } = useSearchLicenses(query, calculateFieldsAllOthersAndConflicts)
  return {
    ...rest,
    data: sortBy(data, (context) => context.license.draft.summary?.processedAt || '').reverse(),
  }
}

export function useSearchLicenses(query: string, calculateFieldsAllOthersAndConflicts: boolean) {
  const result = combineFire3(
    useAllDocuments(),
    useAllLicenseDrafts(),
    useAllApprovedLicensesFlatByUser(),
    (documents, drafts, approved) => ({ documents, drafts, approved: orEmptyObject(approved) })
  )
  return {
    ...result,
    data: result.loadingOrError
      ? []
      : licenseDraftsByCategory(result.data, query, calculateFieldsAllOthersAndConflicts), // calling this function takes ~60ms, which could be optimized
  }
}

export function useLicensesCount() {
  const userContext = useUserContext()

  return combineFire2(useAllLicenseDrafts(), useAllApprovedLicenses(), (drafts, approved) => ({
    drafts: Object.values(drafts)
      .flatMap((draft) =>
        Object.values(draft.categoryDetails || {}).map((detail) => ({
          association: detail.licenseAssociation,
          category: detail.categoryId,
        }))
      )
      .filter(({ association }) => userContext.canViewAssociation(association))
      .filter(({ category }) => categoryByIdRequired(category).year === nextLicenseYear).length,
    approved: Object.values(approved)
      .flatMap((licenses) =>
        Object.values(licenses).map(({ licenseAssociation, categoryId }) => ({
          association: licenseAssociation,
          category: categoryId,
        }))
      )
      .filter(({ association }) => userContext.canViewAssociation(association))
      .filter(({ category }) => categoryByIdRequired(category).year === nextLicenseYear).length,
  }))
}

export function useLicenseCategoryIdsByAssociation(association: AssociationID | '-' | undefined) {
  return combineFire2(useAllLicenseDrafts(), useAllApprovedLicenses(), (drafts, approved) => ({
    drafts: Object.values(drafts).flatMap((draft) => {
      const detailCategoryIds = Object.values(draft.categoryDetails || {})
        .filter((detail) => !association || detail.licenseAssociation === association)
        .map((detail) => detail.categoryId)
      return orEmptyArray(draft.categories).filter((categoryId) =>
        detailCategoryIds.includes(categoryId)
      )
    }),
    approved: Object.values(approved).flatMap((licenses) =>
      Object.values(licenses)
        .filter((license) => !association || license.licenseAssociation === association)
        .map(({ categoryId }) => categoryId)
    ),
  }))
}

export function useLicenseCategoryIds() {
  return combineFire2(useAllLicenseDrafts(), useAllApprovedLicenses(), (drafts, approved) => ({
    drafts: Object.values(drafts).flatMap((draft) => orEmptyArray(draft.categories)),
    approved: Object.values(approved).flatMap((licenses) =>
      Object.values(licenses).map(({ categoryId }) => categoryId)
    ),
  }))
}

export function useAllLicenseDrafts(): UseWithObj<Record<string, LicenseDrafts>> {
  const licenseYear = useLicenseYear()
  const result = useFire<Record<string, LicenseDrafts>>(`licenses/${licenseYear}/drafts`)
  if (result.loadingOrError || !result.data) return { ...result, data: emptyObject }
  const processed = Object.entries(result.data || {}).filter(([_, draft]) => draft.summary?.processed)
  return { ...result, data: Object.fromEntries(processed) }
}

export function useAllApprovedLicensesFlatByUser(): UseWithObj<Record<UserId, ApprovedLicense[]>> {
  const { data, ...rest } = useAllApprovedLicenses()
  return {
    data: data
      ? Object.fromEntries(
          Object.entries(data).map(([userId, values]) => [userId, Object.values(values)])
        )
      : emptyObject,
    ...rest,
  }
}

export function useAllApprovedLicenses() {
  const licenseYear = useLicenseYear()
  const { data, ...rest } = useFire<Record<string, Record<string, ApprovedLicense>>>(
    `licenses/${licenseYear}/approved`
  )
  return { data: orEmptyObject(data), ...rest }
}

export function useApprovedLicense(user: User, licenseYear: Year, categoryID: CategoryId | undefined) {
  return useFire<ApprovedLicense>(
    `licenses/${licenseYear}/approved/${user.uid}/${categoryID || devNull}`
  )
}

export function useApprovedLicenses(user: User, licenseYear: Year) {
  const { data, ...rest } = useFire<Record<string, ApprovedLicense>>(
    `licenses/${licenseYear}/approved/${user.uid}`
  )
  return {
    data: data
      ? Object.fromEntries(Object.entries(data).map(([k, v]) => [k, fixedLicenseType(v)] as const))
      : data,
    ...rest,
  }
}

export function useLicenseDrafts(user: User, licenseYear: Year) {
  const { data, ...rest } = useFire<LicenseDrafts>(`licenses/${licenseYear}/drafts/${user.uid}`)
  return {
    data:
      data && data.categoryDetails
        ? {
            ...data,
            categoryDetails: Object.fromEntries(
              Object.entries(data.categoryDetails).map(([k, v]) => [k, fixedLicenseType(v)] as const)
            ),
          }
        : data,
    ...rest,
  }
}

export function useDocuments(user: User) {
  return useFire<Documents>(`documents/${user.uid}`)
}

export function usePersonalData(user: User) {
  return useFire<PersonalData>(`documents/${user.uid}/personalData`)
}

export function useActiveBikes(user: User) {
  const { data: bikes, ...rest } = useBikes(user)
  return { data: bikes.filter((bike) => bike.status !== 'deleted'), ...rest }
}

export function useBikes(user: User) {
  const { data, ...rest } = useFire<Record<string, Bike>>(`documents/${user.uid}/bikes`)
  return { data: Object.values(data || {}), ...rest }
}

export function useEmergencyData(user: User, year: Year) {
  return combineFire2(useNeedsEmergency(user, year), useRawEmergencyData(user), (needed, emergency) => ({
    needed,
    emergency,
  }))
}

export function useDriversLicense(user: User) {
  return useFire<DriversLicense>(`documents/${user.uid}/driversLicense`)
}

export function useRawEmergencyData(user: User) {
  return useFire<Emergency>(`documents/${user.uid}/emergency`)
}

export function useHealthCheck(user: User, year: Year) {
  return combineFire2(
    useNeedsHealthCheck(user, year),
    useRawHealthCheck(user),
    (needed, healthCheck) => ({ needed, healthCheck })
  )
}

function useRawHealthCheck(user: User) {
  return useFire<HealthCheck>(`documents/${user.uid}/healthCheck`)
}

export function useInsurance(user: User, year: Year) {
  return combineFire2(useNeedsInsurance(user, year), useRawInsurance(user), (needed, insurance) => ({
    needed,
    insurance,
  }))
}

export function useRawInsurance(user: User) {
  return useFire<Insurance>(`documents/${user.uid}/insurance`)
}

export function useTransponderData(user: User) {
  return useFire<Transponder>(`documents/${user.uid}/transponder`)
}

export function usePhoto(user: User) {
  return useFire<Photo>(`documents/${user.uid}/photo`)
}

export function useAllPaymentsWithDocuments() {
  return combineFire2(useAllPaymentsByRider(), useAllDocumentsFlat(), (payments, allDocuments) =>
    allDocuments.map((documents) => ({
      ...documents,
      payments: orEmptyArray(payments[documents.uid]),
    }))
  )
}

export function useSearchDocuments(query: string) {
  const { data, ...rest } = useAllDocuments()
  const documents = searchDocumentsMap(query, data)
  const unsorted = Object.values(documents)

  const sorted = sortBy(unsorted, (documents) => sortName(documents.uid, documents.personalData))

  return { data: sorted, ...rest }
}

export function useSearchDocumentsWithLicenses(query: string) {
  const { data: unsorted, ...rest } = combineFire3(
    useAllDocuments(),
    useAllLicenseDrafts(),
    useAllApprovedLicensesFlatByUser(),
    (documents, drafts, approved) =>
      documentsWithLicenses({
        documents: searchDocumentsMap(query, documents),
        drafts,
        approved: orEmptyObject(approved),
      })
  )

  const sorted = sortBy(unsorted, ({ documents }) => sortName(documents.uid, documents.personalData))

  return { data: sorted, ...rest }
}

function useAllDocumentsFlat() {
  const { data, ...rest } = useAllDocuments()
  return { data: Object.values(data), ...rest }
}

export function useUserData(user: User | undefined) {
  return useFire<UserData>(`users/${user?.uid || '-'}`)
}

export function useOnlineSportEvents(year: Year) {
  const userContext = useUserContext()
  const { data, ...rest } = useSportEventsByYear(year)
  const sportEvents = data.filter(
    (event) => validEventStatus(event, userContext) && isOnlineSportEvent(event)
  )
  return { ...rest, data: sortBy(sportEvents, (sportEvent) => sportEvent.startsAt) }
}

function validEventStatus(event: SportEvent, userContext: UserState) {
  return event.status === 'online' || (event.status === 'draft' && userContext.adminOrAssociationAdmin)
}

// TODO: sportEvents: load only relevant day categories / by year
export function useSportEventsWithDayCategoriesByYear(year: Year) {
  return combineFire2(
    useSportEventsByYear(year),
    useSportEventsDayCategories(),
    (sportEvents, dayCategories) =>
      sportEvents.map((sportEvent) => ({
        sportEvent,
        dayCategories: orEmptyArray(dayCategories[sportEvent.id]),
      }))
  )
}

// TODO: sportEvents: load only relevant day categories / by year
function useSportEventsDayCategories(): UseWithObj<Record<SportEventId, DayCategory[]>> {
  const { data, ...rest } =
    useFire<Record<SportEventId, Record<DayCategoryID, DayCategory>>>('sportEventDayCategories')
  const dayCategories = data
    ? Object.fromEntries(
        Object.entries(data).map(
          ([sportEventID, dayCategories]) => [sportEventID, Object.values(dayCategories)] as const
        )
      )
    : emptyObject
  return le({ data: dayCategories, ...rest })
}

export function useSportEventWithDayCategories(
  sportEventID: SportEventId
): UseWithObj<
  { sportEvent: SportEvent; dayCategories: Record<DayCategoryID, DayCategory> } | undefined
> {
  return combineFire2(
    useSportEvent(sportEventID),
    useSportEventDayCategories(sportEventID),
    (sportEvent, dayCategories) =>
      sportEvent ? { sportEvent, dayCategories: orEmptyObject(dayCategories) } : undefined
  )
}

export function useSportEventDayCategoriesList(sportEventID: SportEventId): UseWithObj<DayCategory[]> {
  const { data, ...rest } = useSportEventDayCategories(sportEventID)
  return { data: Object.values(data || {}), ...rest }
}

export function useSportEventDayCategories(
  sportEventID: SportEventId
): UseWithObj<Record<DayCategoryID, DayCategory> | undefined> {
  return useFire<Record<DayCategoryID, DayCategory>>(`sportEventDayCategories/${sportEventID}`)
}

export function useSportEventDayCategory(props: {
  sportEventID: SportEventId
  dayCategoryID: DayCategoryID
}) {
  const { sportEventID, dayCategoryID } = props
  return useFire<DayCategory>(`sportEventDayCategories/${sportEventID}/${dayCategoryID}`)
}

export function useSportEventsByYear(year: Year) {
  const { data: events, ...rest } = useFire<Record<SportEventId, SportEvent>>(
    `sportEventsByYear/${year}`
  )
  const sportEvents = rest.loadingOrError || !events ? [] : Object.values(events)
  return le({ data: sortBy(sportEvents, (event) => event.startsAt), ...rest })
}

export function useSportEvents() {
  const { data: events, ...rest } = useFire<Record<SportEventId, SportEvent>>('sportEvents')
  const sportEvents = rest.loadingOrError || !events ? [] : Object.values(events)
  return le({ data: sortBy(sportEvents, (event) => event.startsAt), ...rest })
}

export function useSportEventWithInscription(props: SportEventWithInscriptionsProps) {
  return combineFire3(
    useSportEvent(props.sportEventId),
    useSportEventInscription(props),
    useBikes(props.user),
    (sportEvent, inscription, bikes) => ({ sportEvent, inscription, bikes })
  )
}

export function useSportEventInscriptionV1({
  sportEventId,
  categoryId,
  date,
  user: { uid },
}: SportEventWithInscriptionsProps) {
  return useFire<InscriptionV1 | undefined>(
    `sportEventInscription/inscriptions/${sportEventId}/${date}/${categoryId}/${uid}`
  )
}

export function useSportEventInscription({
  sportEventId,
  categoryId,
  date,
  user: { uid },
}: SportEventWithInscriptionsProps) {
  return useFire<Inscription | undefined>(
    `sportEventInscriptionsV2/inscriptions/${sportEventId}/${date}/${categoryId}/${uid}`
  )
}

export interface SportEventWithInscriptionsProps {
  sportEventId: SportEventId
  categoryId: CategoryId | DayCategoryID
  date: SportEventDate
  user: UserQuery
}

export function useSportEventsWithInscriptions(year: Year) {
  return combineFire4(
    useSportEventsWithDayCategoriesByYear(year),
    useAllSportEventInscriptions(),
    useSearchApprovedLicenses('', false),
    useAllDocuments(),
    (sportEvents, inscriptions, licenses, documents) => ({
      sportEvents,
      inscriptions:
        sportEvents && inscriptions && licenses && documents
          ? combineInscriptionsWithLicenses(
              sportEvents.map((event) => ({
                sportEvent: event.sportEvent,
                dayCategories: indexByString(event.dayCategories, (category) => category.id),
              })),
              inscriptions,
              licenses,
              documents
            )
          : [],
    })
  )
}

export function useSportEventWithInscriptions({ q, sportEventId }: { q: string; sportEventId: string }) {
  const { data, ...rest } = combineFire4(
    useSportEventWithDayCategories(sportEventId),
    useSportEventInscriptions({ sportEventId }),
    useSearchApprovedLicenses('', false),
    useAllDocuments(),
    (sportEvent, inscriptions, licenses, documents) => ({
      sportEvent,
      inscriptions:
        sportEvent && inscriptions && licenses && documents
          ? combineInscriptionsWithLicenses([sportEvent], inscriptions, licenses, documents)
          : [],
    })
  )

  const unsortedInscriptions = searchInscriptions(data.inscriptions, q)
  const inscriptions = sortBy(unsortedInscriptions, (inscription) => {
    const rawInscription = inscription.inscription
    const issuedNumber =
      (isDayInscription(rawInscription)
        ? isInscribedInscription(rawInscription)
          ? rawInscription.issuedNumber
          : parseInt10(rawInscription.preferredNumber)
        : isLicenseInscription(rawInscription)
        ? inscription.licenseWithContext?.license.approved.issuedNumber
        : undefined) || 999
    const categoryName =
      categoryByIdFromString(inscription.inscription.category)?.commonName ||
      inscription.dayCategory?.name ||
      '-'
    return [categoryName, issuedNumber.toString().padStart(4, '0')].join(' - ')
  })

  return {
    ...rest,
    data: {
      inscriptions,
      sportEvent: data.sportEvent?.sportEvent,
      dayCategories: data.sportEvent?.dayCategories,
    },
  }
}

function combineInscriptionsWithLicenses(
  sportEvents: {
    sportEvent: SportEvent
    dayCategories: Record<string, DayCategory>
  }[],
  inscriptions: Inscription[],
  licenses: ApprovedLicenseWithContext[],
  allDocuments: Record<UserId, DocumentsWithUid>
): InscriptionWithContextAndSportEvent[] {
  const licensesByUid = groupByString(licenses, (license) => license.license.userId)
  const sportEventsById = Object.fromEntries(
    sportEvents.map((event) => [event.sportEvent.id, event] as const)
  )

  const withContext = inscriptions
    .map<InscriptionWithContextAndSportEvent | undefined>((inscription) => {
      const licensesOfCurrentUser = orEmptyArray(licensesByUid[inscription.uid])
      const approvedLicenseWithContext = licensesOfCurrentUser.find(
        (license) => license.license.approved.categoryId === inscription.category
      )
      const documents = allDocuments[inscription.uid] || approvedLicenseWithContext?.license.documents
      const sportEvent = sportEventsById[inscription.sportEvent]
      const dayCategories: Record<string, DayCategory> = orEmptyObject(sportEvent?.dayCategories)
      return createLicenseWithContext(
        sportEvent?.sportEvent,
        approvedLicenseWithContext,
        inscription,
        documents,
        dayCategories[inscription.category]
      )
    })
    .filter(truthy)
  return sortBy(
    withContext.map((el) => ({
      ...el,
      sortFirst: el.inscription.type,
      sortSecond: el.inscription.category,
      sortThird: inscriptionIssuedOrPreferredNumber(
        el.inscription,
        el.licenseWithContext?.license.approved
      ),
    })),
    ['sortFirst', 'sortSecond', 'sortThird']
  )
}

function createLicenseWithContext(
  sportEvent: SportEvent | undefined,
  licenseWithContext: ApprovedLicenseWithContext | undefined,
  inscription: Inscription,
  documents: DocumentsWithUid | undefined,
  dayCategory: DayCategory | undefined
): InscriptionWithContextAndSportEvent | undefined {
  if (!documents || !sportEvent) return undefined

  return {
    sportEvent,
    inscription,
    licenseWithContext,
    documents,
    status: inscriptionStatus(inscription, licenseWithContext?.tasks),
    dayCategory,
  }
}

export function useFlatSportEventInscriptionsV1({
  sportEventId,
}: {
  sportEventId: SportEventId | undefined
}) {
  return useFire<undefined | Record<CategoryId, Record<UserId, InscriptionV1>>>(
    sportEventId ? `sportEventInscriptions/inscriptions/${sportEventId}` : devNull
  )
}

export function useFlatSportEventInscriptions({
  sportEventId,
}: {
  sportEventId: SportEventId | undefined
}) {
  return useFire<
    undefined | Record<SportEventDate, Record<CategoryId | DayCategoryID, Record<UserId, Inscription>>>
  >(sportEventId ? `sportEventInscriptionsV2/inscriptions/${sportEventId}` : devNull)
}

export function useSportEventInscriptionsV1({
  sportEventId,
}: {
  sportEventId: SportEventId | undefined
}) {
  const { data, ...rest } = useFire<undefined | Record<CategoryId, Record<UserId, InscriptionV1>>>(
    sportEventId ? `sportEventInscriptions/inscriptions/${sportEventId}` : devNull
  )
  return {
    data: data ? Object.values(data).flatMap((byCategory) => Object.values(byCategory)) : [],
    ...rest,
  }
}

export function useSportEventInscriptions({ sportEventId }: { sportEventId: SportEventId | undefined }) {
  const { data, ...rest } = useFire<
    undefined | Record<SportEventDate, Record<CategoryId | DayCategoryID, Record<UserId, Inscription>>>
  >(sportEventId ? `sportEventInscriptionsV2/inscriptions/${sportEventId}` : devNull)
  return {
    data: data
      ? Object.values(data).flatMap((byDate) =>
          Object.values(byDate).flatMap((byCategory) => Object.values(byCategory))
        )
      : [],
    ...rest,
  }
}

export function useAllSportEventInscriptionsV1() {
  const { data: inscriptions, ...rest } = useFire<
    Record<SportEventId, Record<CategoryId, Record<UserId, InscriptionV1>>>
  >('sportEventInscriptions/inscriptions')
  return le({
    data:
      rest.loadingOrError || !inscriptions
        ? []
        : Object.values(inscriptions).flatMap((x) => Object.values(x).flatMap((y) => Object.values(y))),
    ...rest,
  })
}

export function useAllSportEventInscriptions() {
  const { data: inscriptions, ...rest } = useFire<
    Record<
      SportEventId,
      Record<SportEventDate, Record<CategoryId | DayCategoryID, Record<UserId, Inscription>>>
    >
  >('sportEventInscriptionsV2/inscriptions')
  return le({
    data:
      rest.loadingOrError || !inscriptions
        ? []
        : Object.values(inscriptions).flatMap((byDate) =>
            Object.values(byDate).flatMap((byCategory) =>
              Object.values(byCategory).flatMap((byUser) => Object.values(byUser))
            )
          ),
    ...rest,
  })
}

export function useInscriptionsByRiderAndYear(user: UserQuery, year: Year) {
  const { data, ...rest } = useInscriptionsByRider(user)
  return le({ data: data.filter((inscription) => inscription.year === year), ...rest })
}

export function useInscriptionsByRider({ uid }: UserQuery) {
  const { data: inscriptions, ...rest } = useFire<
    Record<SportEventId, Record<SportEventDate, Record<CategoryId | DayCategoryID, Inscription>>>
  >(`sportEventInscriptionsV2/byRider/${uid}`)
  return le({
    data:
      rest.loadingOrError || !inscriptions
        ? []
        : Object.values(inscriptions).flatMap((byDate) =>
            Object.values(byDate).flatMap((byCategory) => Object.values(byCategory))
          ),
    ...rest,
  })
}

export function usePublicSportEventInscriptionsV1(sportEvent: SportEvent, category: Category) {
  const { data: inscriptions, ...rest } = useFire<Record<UserId, PublicInscription>>(
    `sportEventInscriptions/public/${sportEvent.id}/${category.id}`
  )
  return le({
    data: rest.loadingOrError || !inscriptions ? [] : Object.values(inscriptions),
    ...rest,
  })
}

export function usePublicSportEventInscriptions(
  sportEvent: SportEvent,
  date: SportEventDate,
  categories: (Category | DayCategory)[]
) {
  const { data: inscriptions, ...rest } = useFire<
    Record<CategoryId | DayCategoryID, Record<UserId, PublicInscription>>
  >(`sportEventInscriptionsV2/public/${sportEvent.id}/${date}`)
  const selectedCategoryIds = categories.map((category) => category.id)
  const list =
    rest.loadingOrError || !inscriptions
      ? []
      : Object.entries(inscriptions)
          .filter(([id]) => selectedCategoryIds.includes(id))
          .flatMap(([, inscriptions]) => Object.values(inscriptions))
  return le({ data: sortPublicInscriptions(list), ...rest })
}

function sortPublicInscriptions(inscriptions: PublicInscription[]) {
  return sortBy(inscriptions, (inscription) =>
    migrateInscribed(inscription.inscribed)
      ? inscription.startingNumber
      : typeof inscription.startingNumber === 'number'
      ? inscription.startingNumber + 10000
      : 999999999
  )
}

export function useActiveSportEvent(user: UserQuery) {
  const { data: id } = useActiveSportEventId(user)
  return useSportEvent(id)
}

export function useActiveSportEventId(user: UserQuery) {
  return useFire<string | undefined>(`activeSportEvents/byUser/${user.uid}`)
}

export function useSportEvent(id: string | undefined) {
  return useFire<SportEvent | undefined>(id ? `sportEvents/${id}` : devNull)
}

/**
 * React hooks cannot have conditionals, so _some_ query always needs to be executed
 * => this is the placeholder for a "null" firebase database query, which should always return undefined
 */
const devNull = 'dev/null'
