import assertNever from 'assert-never'
import firebase from 'firebase'
import { clamp, last, sortBy, uniq } from 'lodash'
import type { Debitor, ParsedPayment } from 'shared/billing/payment-interfaces'
import type { PlatformCreditor } from 'shared/config/creditor'
import { categoryOfAssociation, fixedLicenseType } from 'shared/data/categories-service'
import { generateCode } from 'shared/data/generateCode'
import type { LicenseTasksOverview } from 'shared/data/license-tasks-overview'
import type { Year } from 'shared/data/license-year'
import type { Association } from 'shared/db/association'
import type { DayCategoryID, DayCategory, InscriptionCategoryID } from 'shared/db/day-category'
import type { MigrationState } from 'shared/db/db-migrations'
import type { LicenseType } from 'shared/db/license-type'
import { todoMigrateInscriptionBooking, todoMigrateLicenseBooking } from 'shared/db/migrate-bookings'
import { ShortIdMapping } from 'shared/db/ShortIdMapping'
import type { SportEventId } from 'shared/db/sport-event-id'
import { sortTransactions } from 'shared/db/transactions-service'
import type { UserEvent } from 'shared/db/user-event'
import type { UserId } from 'shared/db/user-id'
import type { I18nLocales } from 'shared/i18n/i18n-types'
import type { InscriptionStatus } from 'shared/inscription/inscription-status'
import { AssociationID } from 'shared/models/associations'
import type { Bike } from 'shared/models/bike'
import type { Category, CategoryId } from 'shared/models/category'
import type { CategoryDetails } from 'shared/models/category-details'
import { DriversLicense } from 'shared/models/DriversLicense'
import type { Emergency } from 'shared/models/emergency'
import type { HealthCheck } from 'shared/models/health-check'
import type { Insurance } from 'shared/models/insurance'
import type { LastYear } from 'shared/models/last-year'
import type { PersonalData } from 'shared/models/personal-data'
import type { Photo } from 'shared/models/photo'
import type { SelectedCategory } from 'shared/models/selected-category'
import type { StoredUser } from 'shared/models/stored-user'
import type { Summary } from 'shared/models/summary'
import type { Transponder, TransponderOptionId } from 'shared/models/transponder'
import type { TransponderType } from 'shared/models/transponder-type'
import type { UploadMetadata, UploadStatusDetails } from 'shared/models/upload-status'
import {
  EnlistedInscription,
  Inscription,
  InscriptionV1,
  isEnlistedInscription,
  PublicInscription,
  SportEvent,
} from 'shared/sport-events/sport-events'
import { truthy } from 'shared/utils/array'
import { base64UrlEncode } from 'shared/utils/base64'
import type { DateString } from 'shared/utils/date'
import { parseInt10, toChf, validNumber } from 'shared/utils/number'
import { mapValues } from 'shared/utils/object'

export abstract class DB {
  async setMigrationExecuted(id: string) {
    const state: MigrationState = { id, runAt: new Date().toISOString() }
    await this.set(`migrations/executed/${id}`, state)
  }

  async deleteMigrationExecution(id: string) {
    await this.set(`migrations/executed/${id}`, null)
  }

  async loadAllDayCategoriesOnlyForMigration(): Promise<
    Record<SportEventId, Record<DayCategoryID, DayCategory>>
  > {
    const result = await this.load<Record<SportEventId, Record<DayCategoryID, DayCategory>>>(
      `sportEventDayCategories`
    )
    return result || {}
  }

  async loadAllDayCategoriesById(year: Year): Promise<Record<string, DayCategory>> {
    const sportEvents = await this.loadAllSportEventsOfYearById(year)
    const allSportEventDayCategories = await Promise.all(
      Object.keys(sportEvents).map((sportEventId) => this.loadDayCategoryBySportEvent({ sportEventId }))
    )

    return Object.fromEntries(
      allSportEventDayCategories.flatMap((dayCategories) =>
        Object.values(dayCategories).map((dayCategory) => [dayCategory.id, dayCategory] as const)
      )
    )
  }

  private async loadDayCategoryBySportEvent(props: { sportEventId: SportEventId }) {
    const result = await this.load<Record<DayCategoryID, DayCategory>>(
      `sportEventDayCategories/${props.sportEventId}`
    )
    return result || {}
  }

  loadDayCategory(props: { sportEvent: SportEventId; category: DayCategoryID }) {
    const { sportEvent, category } = props
    return this.load<DayCategory>(`sportEventDayCategories/${sportEvent}/${category}`)
  }

  async protocolEmergencyObservation(props: { affectedUid: string; viewerUid: string }) {
    await this.push(`emergencyObservations/${props.affectedUid}`, {
      ...props,
      id: undefined,
      date: new Date().toISOString(),
    })
  }

  shortUid(uid: string) {
    return this.load<string | undefined>(`shortUids/uidToShortUid/${uid}`)
  }

  uidForShortUid(shortUid: string) {
    return this.load<string | undefined>(`shortUids/shortUidToUid/${shortUid}`)
  }

  async allShortUids(): Promise<ShortUids> {
    const ids = await this.load<ShortUids | undefined>('shortUids')
    return ids || { uidToShortUid: {}, shortUidToUid: {} }
  }

  writeShortUids(shortUids: ShortUids) {
    return this.set<ShortUids | undefined>('shortUids', shortUids)
  }

  paymentReadyMailSent(user: UserQuery, year: number) {
    return this.mailSent(user, 'payment-ready', year)
  }

  setPaymentReadyMailSent(user: UserQuery, licenseYear: number) {
    return this.setMailSent(user, 'payment-ready', licenseYear)
  }

  async mailSent(user: UserQuery, type: string, licenseYear: number) {
    return !!(await this.load<boolean | undefined>(`mails/${type}/${licenseYear}/${user.uid}`))
  }

  async setMailSent(user: UserQuery, type: string, licenseYear: number) {
    await this.set(`mails/${type}/${licenseYear}/${user.uid}`, true)
  }

  async loadLicenseData(user: UserQuery, licenseYear: number): Promise<LicenseFormData> {
    const [documents, licenseDrafts, approvedLicenses] = await Promise.all([
      this.loadDocuments(user),
      this.loadLicenseDrafts(user, licenseYear),
      this.loadApprovedLicenses(user, licenseYear),
    ])
    return { documents, licenseDrafts, approvedLicenses }
  }

  async loadApprovedLicenses(user: UserQuery, licenseYear: number) {
    const records = await this.load<Record<string, ApprovedLicense> | undefined>(
      `licenses/${licenseYear}/approved/${user.uid}`
    )
    return records ? Object.values(records) : []
  }

  async loadApprovedLicenseByShortId(
    licenseYear: number,
    shortId: number
  ): Promise<ApprovedLicense | undefined> {
    const mapping = await this.shortIdMappingByShortId({ licenseYear, shortId })
    if (!mapping) return undefined

    return this.loadApprovedLicense(mapping, mapping.categoryId, licenseYear)
  }

  async loadAllApprovedLicenses(licenseYear: number) {
    const byUser = await this.loadAllApprovedLicensesByUserByCategory(licenseYear)
    return byUser
      ? Object.values(byUser).flatMap((byCategory) => (byCategory ? Object.values(byCategory) : []))
      : []
  }

  async loadAllApprovedLicensesByUserByCategory(licenseYear: number) {
    const byUser = await this.load<Record<string, Record<CategoryId, ApprovedLicense>> | undefined>(
      `licenses/${licenseYear}/approved`
    )
    return byUser || {}
  }

  async loadTransactions(user: UserQuery): Promise<Transaction[]> {
    return sortTransactions(
      (
        await Promise.all([
          this.loadInscriptionBookings(user),
          this.loadLicenseBookings(user),
          this.loadManualBookings(user),
          this.loadBills(user),
          this.loadPayments(user),
        ])
      ).flat()
    ).reverse()
  }

  async updateTransactionRemainingBalance(t: Transaction) {
    if (t.type === 'licenseBooking' || t.type === 'reverseLicenseBooking')
      await this.updateLicenseBookingRemainingBalance(t)
    else if (t.type === 'inscriptionBooking') await this.updateInscriptionBookingRemainingBalance(t)
    else if (t.type === 'manualBooking') await this.updateManualBookingRemainingBalance(t)
    else if (t.type === 'manualPayment') await this.updatePaymentRemainingBalance(t)
    else if (t.type === 'payment') {
      const automaticPayment = t as AutomaticPaymentWithoutUid | AutomaticPayment
      if ('uid' in automaticPayment && automaticPayment.uid)
        await this.updatePaymentRemainingBalance(automaticPayment)
    } else if (t.type === 'bill') {
      await this.updateBillRemainingBalance(t)
    } else if (t.type === 'associationPayment') {
      await this.updateAssociationPaymentRemainingBalance(t)
    } else assertNever(t)
  }

  private async updateInscriptionBookingRemainingBalance(booking: InscriptionBooking) {
    await this.set<number | undefined>(
      `inscriptionBookings/byRider/${booking.uid}/${booking.id}/remainingBalance`,
      booking.remainingBalance
    )
  }

  private async updateLicenseBookingRemainingBalance(booking: LicenseBooking) {
    await this.set<number | undefined>(
      `licenseBookings/byRider/${booking.uid}/${booking.id}/remainingBalance`,
      booking.remainingBalance
    )
  }

  private async updateManualBookingRemainingBalance(booking: ManualBooking) {
    await this.set<number | undefined>(
      `manualBookings/byRider/${booking.uid}/${booking.id}/remainingBalance`,
      booking.remainingBalance
    )
  }

  private async updatePaymentRemainingBalance(payment: ManualPayment | AutomaticPayment) {
    await this.set<number | undefined>(
      `payments/byRider/${payment.uid}/${payment.id}/remainingBalance`,
      payment.remainingBalance
    )
  }

  private async updateAssociationPaymentRemainingBalance(
    payment: AssociationPaymentRequested | AssociationPaymentPaid
  ) {
    await this.set(
      `associationPayments/${payment.association}/${payment.id}/remainingBalance`,
      payment.remainingBalance
    )
  }

  private async updateBillRemainingBalance(bill: Bill) {
    await this.setBillRemainingBalance(bill, bill.remainingBalance)
  }

  async loadPayments(user: UserQuery) {
    const payments = await this.loadRecords<Payment>(`payments/byRider/${user.uid}`)

    return payments.map((payment) => {
      payment.uid = user.uid
      return payment
    })
  }

  async loadAllPayments() {
    const payments = await this.load<Record<UserId, Record<UserId, ManualPayment | AutomaticPayment>>>(
      'payments/byRider'
    )
    return Object.values(payments || {}).flatMap((byPaymentId) => Object.values(byPaymentId || {}))
  }

  async loadAllManualPayments() {
    const payments = await this.load<Record<UserId, Record<UserId, ManualPayment | AutomaticPayment>>>(
      'payments/byRider'
    )
    return Object.values(payments || {}).flatMap((byPaymentId) =>
      Object.values(byPaymentId || {}).filter(isManualPayment)
    )
  }

  async updateManualPayment(payment: ManualPayment) {
    const path = `payments/byRider/${payment.uid}/${payment.id}`
    const before = await this.load<ManualPayment>(path)
    await this.set(path, payment)

    if (before?.tag === payment.tag) return
    if (before) await this.decrementManualPaymentTagsCache(before.association, before.tag)
    await this.incrementManualPaymentTagsCache(payment.association, payment.tag)
  }

  async replaceManualPaymentMethodWithTag(payment: ManualPayment, tag: string) {
    if (!payment.uid || !payment.id) throw new Error('Payment must have uid and id')

    await this.setManualPaymentTag(payment, tag)
    await this.delete(`payments/byRider/${payment.uid}/${payment.id}/paymentMethod`)
  }

  async setManualPaymentTag(payment: ManualPayment, tag: string) {
    if (!payment.uid || !payment.id) throw new Error('Payment must have uid and id')

    const path = `payments/byRider/${payment.uid}/${payment.id}`

    const before = await this.load<ManualPayment>(path)
    await this.set(`${path}/tag`, tag)

    if (!before || before.tag === tag) return
    await this.decrementManualPaymentTagsCache(before.association, before.tag)
    await this.incrementManualPaymentTagsCache(before.association, tag)
  }

  async setManualPaymentTagsCache(tags: Record<AssociationID, Record<string, number>>) {
    await this.set('caches/manualPaymentTags', tags)
  }

  async setManualBookingTagsCache(tags: Record<AssociationID, Record<string, number>>) {
    await this.set('caches/manualBookingTags', tags)
  }

  async incrementManualPaymentTagsCache(association: AssociationID | undefined, tag: string) {
    if (!tag) return
    await this.set(
      `caches/manualPaymentTags/${association}/${base64UrlEncode(tag)}`,
      firebase.database.ServerValue.increment(1)
    )
  }

  async incrementManualBookingTagsCache(association: AssociationID | undefined, tag: string) {
    if (!tag) return
    await this.set(
      `caches/manualBookingTags/${association}/${base64UrlEncode(tag)}`,
      firebase.database.ServerValue.increment(1)
    )
  }

  async decrementManualPaymentTagsCache(association: AssociationID | undefined, tag: string) {
    if (!tag) return
    await this.set(
      `caches/manualPaymentTags/${association}/${base64UrlEncode(tag)}`,
      firebase.database.ServerValue.increment(-1)
    )
  }

  async decrementManualBookingTagsCache(association: AssociationID | undefined, tag: string) {
    if (!tag) return
    await this.set(
      `caches/manualBookingTags/${association}/${base64UrlEncode(tag)}`,
      firebase.database.ServerValue.increment(-1)
    )
  }

  async registerPaymentFile(paymentFileWithoutId: PaymentFile) {
    const paymentFile = await this.push<PaymentFile>(`paymentFiles`, paymentFileWithoutId)
    paymentFile.parsed.transactions.forEach(
      (transaction) => (transaction.paymentFileId = paymentFile.id)
    )
    const maybeUids = await Promise.all(
      paymentFile.parsed.transactions.map(async (transaction) => {
        if (transaction.hasError) return undefined
        return await this.registerPayment({ ...transaction, type: 'payment' })
      })
    )
    return uniq(maybeUids.filter(truthy))
  }

  async assignInvalidPayment({ bill, payment }: { bill: Bill; payment: InvalidPayment }) {
    payment.originalReference = payment.reference
    payment.reference = bill.reference
    await this.updateInvalidPayment(payment)
    await this.registerValidPayment(payment, bill)
    await this.updateBillFromPayment(payment, bill)
  }

  async unassignInvalidPayment(payment: AutomaticPaymentWithoutUid) {
    const bill = await this.loadBill(payment.reference)
    if (!bill) throw new Error(`Bill not found for reference ${payment.reference}`)
    if (!payment.originalReference) throw new Error(`No original reference set`)

    const invalidPayment: InvalidPayment = {
      ...payment,
      reference: payment.originalReference,
      originalReference: '',
    }
    await this.updateInvalidPayment(invalidPayment)
    await this.deleteValidPayment(payment, bill)
  }

  async deletePayment(payment: AutomaticPaymentWithoutUid) {
    const bill = await this.loadBill(payment.reference)
    if (!bill) throw new Error(`Bill not found for reference ${payment.reference}`)

    await this.movePaymentToDeleted(payment, bill)
  }

  private async registerPayment(payment: AutomaticPaymentWithoutUid) {
    const bill = await this.loadBill(payment.reference)
    if (!bill) {
      await this.registerInvalidPayment(payment)
    } else {
      await this.registerValidPayment(payment, bill)
      await this.updateBillFromPayment(payment, bill)
    }
    return bill?.uid
  }

  async updateBillFromPayment(payment: PaymentWithoutUid, bill: Bill) {
    if (bill.status === 'open') {
      const id = payment.id
      await this.set<Bill['paymentId']>(`bills/byReference/${bill.reference}/paymentId`, id)
      await this.set<Bill['paymentId']>(`bills/byRider/${bill.uid}/${bill.reference}/paymentId`, id)
      const newStatus: Bill['status'] =
        toChf(bill.paymentInfo.amount) === toChf(payment.amount)
          ? 'paid'
          : bill.paymentInfo.amount < payment.amount
          ? 'overpaid'
          : 'underpaid'
      await this.setBillStatus(bill, newStatus)
      await this.setBillPaidAt(bill, payment.date)
    }
  }

  async setBillStatus(bill: Bill, newStatus: Bill['status']) {
    await this.set<Bill['status']>(`bills/byReference/${bill.reference}/status`, newStatus)
    await this.set<Bill['status']>(`bills/byRider/${bill.uid}/${bill.reference}/status`, newStatus)
  }

  async setBillPaidAt(bill: Bill, paidAt: Bill['paidAt'] | null) {
    await this.set<Bill['paidAt'] | null>(`bills/byReference/${bill.reference}/paidAt`, paidAt)
    await this.set<Bill['paidAt'] | null>(`bills/byRider/${bill.uid}/${bill.reference}/paidAt`, paidAt)
  }

  async setBillRemainingBalance(bill: Bill, remainingBalance: Bill['remainingBalance']) {
    await this.set<Bill['remainingBalance'] | null>(
      `bills/byReference/${bill.reference}/remainingBalance`,
      remainingBalance
    )
    await this.set<Bill['remainingBalance'] | null>(
      `bills/byRider/${bill.uid}/${bill.reference}/remainingBalance`,
      remainingBalance
    )
  }

  private async registerInvalidPayment(payment: AutomaticPaymentWithoutUid) {
    if (await this.load<InvalidPayment>(`payments/invalid/${payment.id}`)) return
    await this.updateInvalidPayment({ originalReference: '', ...payment })
  }

  private async updateInvalidPayment(payment: InvalidPayment) {
    await this.set<InvalidPayment>(`payments/invalid/${payment.id}`, payment)
  }

  async deleteInvalidPayment(payment: InvalidPayment) {
    await this.moveRef(`payments/invalid/${payment.id}`, `deleted/payments/invalid/${payment.id}`)
  }

  private registerValidPayment(payment: AutomaticPaymentWithoutUid, { uid }: Bill) {
    return this.set<AutomaticPayment>(`payments/byRider/${uid}/${payment.id}`, {
      ...payment,
      uid,
    })
  }

  private deleteValidPayment(payment: AutomaticPaymentWithoutUid, { uid }: Bill) {
    return this.set<null>(`payments/byRider/${uid}/${payment.id}`, null)
  }

  private movePaymentToDeleted(payment: AutomaticPaymentWithoutUid, { uid }: Bill) {
    return this.moveRef(`payments/byRider/${uid}/${payment.id}`, `payments/deleted/${uid}/${payment.id}`)
  }

  async pushManualPayment(payment: WithoutTimestamps<ManualPayment>) {
    const createdPayment = await this.push<ManualPayment>(
      `payments/byRider/${payment.uid}`,
      this.withTimestamps(payment)
    )
    await this.incrementManualPaymentTagsCache(payment.association, payment.tag)
    return createdPayment
  }

  private withTimestamps<T>(obj: T): T & { createdAt: string; updatedAt: string } {
    return { createdAt: new Date().toISOString(), ...obj, updatedAt: new Date().toISOString() }
  }

  async deleteManualPayment(payment: ManualPayment) {
    const path = `payments/byRider/${payment.uid}/${payment.id}`
    const before = await this.load<ManualPayment>(path)
    await this.delete(path)
    if (before) await this.decrementManualPaymentTagsCache(before.association, before.tag)
  }

  async loadAllManualBookings() {
    const grouped = await this.load<Record<UserId, Record<string, ManualBooking>>>(
      `manualBookings/byRider`
    )
    return Object.values(grouped || {}).flatMap((group) => Object.values(group))
  }

  loadManualBookings(user: UserQuery) {
    return this.loadRecords<ManualBooking>(`manualBookings/byRider/${user.uid}`)
  }

  async pushManualBooking(booking: ManualBooking) {
    await this.push<ManualBooking>(`manualBookings/byRider/${booking.uid}`, booking)
    await this.incrementManualBookingTagsCache(booking.item.association, booking.tag)
  }

  async updateManualBooking(booking: ManualBooking) {
    const path = `manualBookings/byRider/${booking.uid}/${booking.id}`
    const before = await this.load<ManualBooking>(path)
    await this.set<ManualBooking>(path, booking)

    if (before?.tag === booking.tag) return
    if (before) await this.decrementManualBookingTagsCache(before.item.association, before.tag)
    await this.incrementManualBookingTagsCache(booking.item.association, booking.tag)
  }

  async deleteManualBooking(booking: ManualBooking) {
    const path = `manualBookings/byRider/${booking.uid}/${booking.id}`
    const data = await this.load<ManualBooking>(path)
    await this.delete(path)
    if (data) await this.decrementManualBookingTagsCache(data.item.association, data.tag)
  }

  pushAssociationPayment(
    payment: WithoutTimestamps<AssociationPaymentRequested> | WithoutTimestamps<AssociationPaymentPaid>
  ) {
    return this.push<AssociationPayment>(
      `associationPayments/${payment.association}`,
      this.withTimestamps(payment)
    )
  }

  updateAssociationPayment(
    payment: Omit<AssociationPaymentRequested, 'updatedAt'> | Omit<AssociationPaymentPaid, 'updatedAt'>
  ) {
    return this.set<AssociationPayment>(
      `associationPayments/${payment.association}/${payment.id}`,
      this.withTimestamps(payment)
    )
  }

  deleteAssociationPayment(payment: AssociationPayment) {
    return this.delete(`associationPayments/${payment.association}/${payment.id}`)
  }

  async loadInscriptionBookingsByRider() {
    const byRider = await this.load<Record<UserId, Record<string, InscriptionBooking>>>(
      `inscriptionBookings/byRider`
    )
    return mapValues(byRider || {}, (bookings) =>
      Object.values(bookings).map((booking) => todoMigrateInscriptionBooking(booking))
    )
  }

  async loadInscriptionBookingsAndSportEvent(user: UserQuery, sportEvent: { id: SportEventId }) {
    return (await this.loadInscriptionBookings(user)).filter(
      (booking) => booking.item.sportEventId === sportEvent.id
    )
  }

  async loadInscriptionBookings(user: UserQuery) {
    const data = await this.loadRecords<InscriptionBooking>(`inscriptionBookings/byRider/${user.uid}`)

    return data.map((booking) => todoMigrateInscriptionBooking(booking))
  }

  async loadLicenseBookings(user: UserQuery) {
    const records = await this.loadRecords<LicenseBooking>(`licenseBookings/byRider/${user.uid}`)
    return records.map((booking) => todoMigrateLicenseBooking(booking))
  }

  async loadAllLicenseBookings() {
    const grouped = await this.load<Record<UserId, Record<string, LicenseBooking>>>(
      `licenseBookings/byRider`
    )
    return Object.values(grouped || {}).flatMap((group) => Object.values(group))
  }

  pushInscriptionBooking(booking: InscriptionBooking) {
    return this.push<InscriptionBooking>(`inscriptionBookings/byRider/${booking.uid}`, booking)
  }

  updateInscriptionBooking(booking: InscriptionBooking) {
    return this.set<InscriptionBooking>(
      `inscriptionBookings/byRider/${booking.uid}/${booking.id}`,
      booking
    )
  }

  pushLicenseBooking(booking: LicenseBooking) {
    return this.push<LicenseBooking>(`licenseBookings/byRider/${booking.uid}`, booking)
  }

  updateLicenseBooking(booking: LicenseBooking) {
    return this.set<LicenseBooking>(`licenseBookings/byRider/${booking.uid}/${booking.id}`, booking)
  }

  async loadAllSportEvents() {
    const events = await this.loadAllSportEventsById()
    return Object.values(events || {})
  }

  private async loadAllSportEventsById() {
    return (await this.load<Record<SportEventId, SportEvent>>('sportEvents')) || {}
  }

  async loadAllSportOfYearEvents(year: Year) {
    const events = await this.loadAllSportEventsOfYearById(year)
    return Object.values(events || {})
  }

  async loadAllSportEventsOfYearById(year: Year) {
    return (await this.load<Record<SportEventId, SportEvent>>(`sportEventsByYear/${year}`)) || {}
  }

  async pushSportEvent(sportEvent: SportEvent) {
    const created = await this.push<SportEvent>('sportEvents', sportEvent)
    await this.set(`sportEventsByYear/${sportEvent.year}/${sportEvent.id}`, sportEvent)
    return created
  }

  async updateSportEvent(sportEvent: SportEvent) {
    const oldSportEvent = await this.loadSportEvent(sportEvent.id)
    if (oldSportEvent && oldSportEvent.year !== sportEvent.year)
      await this.delete(`sportEventsByYear/${oldSportEvent.year}/${oldSportEvent.id}`)

    await this.set(`sportEvents/${sportEvent.id}`, sportEvent)
    await this.set(`sportEventsByYear/${sportEvent.year}/${sportEvent.id}`, sportEvent)
  }

  async updateSportEventGroupCount(sportEvent: SportEvent, lookup: string, newGroupSizeNumber: number) {
    const clamped = clamp(newGroupSizeNumber, 1, 1000)
    const sizeToStore = clamped <= 1 ? null : clamped
    await this.set(`sportEvents/${sportEvent.id}/categoryGroupCounts/${lookup}`, sizeToStore)
    await this.set(
      `sportEventsByYear/${sportEvent.year}/${sportEvent.id}/categoryGroupCounts/${lookup}`,
      sizeToStore
    )
  }

  deleteSportEventDayCategories({ id }: { id: SportEventId }) {
    return this.delete(`sportEventDayCategories/${id}`)
  }

  pushSportEventDayCategory(dayCategory: DayCategory) {
    return this.push(`sportEventDayCategories/${dayCategory.sportEvent}`, dayCategory)
  }

  updateSportEventDayCategory(dayCategory: DayCategory) {
    return this.set(`sportEventDayCategories/${dayCategory.sportEvent}/${dayCategory.id}`, dayCategory)
  }

  async deleteSportEvent(sportEvent: SportEvent) {
    await this.set(`sportEvents/${sportEvent.id}/status`, 'deleted')
    await this.set(`sportEventsByYear/${sportEvent.year}/${sportEvent.id}/status`, 'deleted')
  }

  loadSportEvent(id: string) {
    return this.load<SportEvent>(`sportEvents/${id}`)
  }

  async loadAllInscriptionsV1(): Promise<InscriptionV1[]> {
    const byEvent = await this.load<
      Record<SportEventId, Record<InscriptionCategoryID, Record<UserId, InscriptionV1>>>
    >(`sportEventInscriptions/inscriptions`)
    if (!byEvent) return []

    return Object.values(byEvent).flatMap((byCategory) =>
      Object.values(byCategory).flatMap((byUser) => Object.values(byUser))
    )
  }

  async loadAllInscriptions(): Promise<Inscription[]> {
    const byEvent = await this.load<
      Record<
        SportEventId,
        Record<DateString, Record<InscriptionCategoryID, Record<UserId, Inscription>>>
      >
    >(`sportEventInscriptionsV2/inscriptions`)
    if (!byEvent) return []

    return Object.values(byEvent).flatMap((byDate) =>
      Object.values(byDate).flatMap((byCategory) =>
        Object.values(byCategory).flatMap((byUser) => Object.values(byUser))
      )
    )
  }

  async loadInscriptionsByUser(user: UserQuery): Promise<Inscription[]> {
    const byEvent = await this.load<
      Record<SportEventId, Record<DateString, Record<InscriptionCategoryID, Inscription>>>
    >(`sportEventInscriptionsV2/byRider/${user.uid}`)
    if (!byEvent) return []

    return Object.values(byEvent).flatMap((byDate) =>
      Object.values(byDate).flatMap((byCategory) => Object.values(byCategory))
    )
  }

  async loadInscriptionsByUserAndSportEvent(
    user: UserQuery,
    sportEvent: SportEvent
  ): Promise<Inscription[]> {
    const byDate = await this.load<Record<DateString, Record<InscriptionCategoryID, Inscription>>>(
      `sportEventInscriptionsV2/byRider/${user.uid}/${sportEvent.id}`
    )
    if (!byDate) return []

    return Object.values(byDate).flatMap((byCategory) => Object.values(byCategory))
  }

  async loadInscriptionsBySportEvent(sportEvent: SportEvent): Promise<Inscription[]> {
    const byEvent = await this.load<Record<DateString, Record<InscriptionCategoryID, Inscription>>>(
      `sportEventInscriptionsV2/inscriptions/${sportEvent.id}`
    )
    if (!byEvent) return []

    return Object.values(byEvent).flatMap((byDate) =>
      Object.values(byDate).flatMap((byCategory) => Object.values(byCategory))
    )
  }

  loadInscription(inscription: Inscription) {
    return this.load<Inscription | undefined>(
      `sportEventInscriptionsV2/inscriptions/${inscription.sportEvent}/${inscription.date}/${inscription.category}/${inscription.uid}`
    )
  }

  async setInscription(inscription: Inscription) {
    await this.set(
      `sportEventInscriptionsV2/inscriptions/${inscription.sportEvent}/${inscription.date}/${inscription.category}/${inscription.uid}`,
      inscription
    )
    await this.set(
      `sportEventInscriptionsV2/byRider/${inscription.uid}/${inscription.sportEvent}/${inscription.date}/${inscription.category}`,
      inscription
    )
  }

  async deleteInscription(inscription: Inscription) {
    await this.set(
      `sportEventInscriptionsV2/inscriptions/${inscription.sportEvent}/${inscription.date}/${inscription.category}/${inscription.uid}`,
      null
    )
    await this.set(
      `sportEventInscriptionsV2/byRider/${inscription.uid}/${inscription.sportEvent}/${inscription.date}/${inscription.category}`,
      null
    )
    await this.set(
      `sportEventInscriptionsV2/public/${inscription.sportEvent}/${inscription.date}/${inscription.category}/${inscription.uid}`,
      null
    )
    await this.set(
      `sportEventInscriptionsV2/paymentStatus/${inscription.sportEvent}/${inscription.date}/${inscription.category}/${inscription.uid}`,
      null
    )
  }

  async clearInscriptionPublicStatistics(sportEvent: SportEvent) {
    await this.delete(`sportEventInscriptionsV2/publicStatistics/${sportEvent.id}`)
  }

  async updateInscriptionPublicStatistics(inscription: Inscription) {
    const enlisted = isEnlistedInscription(inscription)
    const path = `sportEventInscriptionsV2/publicStatistics/${inscription.sportEvent}/${inscription.date}/${inscription.category}/${inscription.uid}`

    if (enlisted)
      await this.set(path, {
        enlisted,
        paid: inscription.paid,
        enlistedAt: inscription.createdAt,
        // TODO: later: this doesnt't work yet, because paidAt is not calculated yet: paidAt: inscription.paidAt,
      })
    else await this.delete(path)
  }

  async publicInscriptionStatistics(props: {
    sportEvent: SportEventId
    date: DateString
    category: InscriptionCategoryID
  }) {
    const { sportEvent, date, category } = props
    const data = await this.load<Record<UserId, PublicInscriptionStatus>>(
      `sportEventInscriptionsV2/publicStatistics/${sportEvent}/${date}/${category}`
    )
    Object.values(data || {}).reduce(
      (acc, status) => ({
        paid: acc.paid + (status.paid ? 1 : 0),
        enlisted: acc.enlisted + (status.enlisted ? 1 : 0),
      }),
      { paid: 0, enlisted: 0 }
    )
  }

  async setInscriptionPaid(inscription: Inscription, paid: boolean) {
    await this.set(
      `sportEventInscriptionsV2/inscriptions/${inscription.sportEvent}/${inscription.date}/${inscription.category}/${inscription.uid}/paid`,
      paid
    )
    await this.set(
      `sportEventInscriptionsV2/byRider/${inscription.uid}/${inscription.sportEvent}/${inscription.date}/${inscription.category}/paid`,
      paid
    )
  }

  async setInscriptionIssuedNumber(inscription: Inscription, issuedNumber: number | null) {
    await this.set(
      `sportEventInscriptionsV2/inscriptions/${inscription.sportEvent}/${inscription.date}/${inscription.category}/${inscription.uid}/issuedNumber`,
      issuedNumber
    )
    await this.set(
      `sportEventInscriptionsV2/byRider/${inscription.uid}/${inscription.sportEvent}/${inscription.date}/${inscription.category}/issuedNumber`,
      issuedNumber
    )
  }

  async setInscriptionBorrowedTransponder(inscription: Inscription, borrowedTransponder: string | null) {
    await this.set(
      `sportEventInscriptionsV2/inscriptions/${inscription.sportEvent}/${inscription.date}/${inscription.category}/${inscription.uid}/borrowedTransponder`,
      borrowedTransponder
    )
    await this.set(
      `sportEventInscriptionsV2/byRider/${inscription.uid}/${inscription.sportEvent}/${inscription.date}/${inscription.category}/borrowedTransponder`,
      borrowedTransponder
    )
  }

  async setInscriptionSidecarPartner(inscription: Inscription, sidecarPartner: string) {
    await this.set(
      `sportEventInscriptionsV2/inscriptions/${inscription.sportEvent}/${inscription.date}/${inscription.category}/${inscription.uid}/sidecarPartner`,
      sidecarPartner
    )
    await this.set(
      `sportEventInscriptionsV2/byRider/${inscription.uid}/${inscription.sportEvent}/${inscription.date}/${inscription.category}/sidecarPartner`,
      sidecarPartner
    )
  }

  async setInscriptionGroup(inscription: Inscription, group: number | null) {
    await this.set(
      `sportEventInscriptionsV2/inscriptions/${inscription.sportEvent}/${inscription.date}/${inscription.category}/${inscription.uid}/group`,
      group
    )
    await this.set(
      `sportEventInscriptionsV2/byRider/${inscription.uid}/${inscription.sportEvent}/${inscription.date}/${inscription.category}/group`,
      group
    )
  }

  async manuallyVerifyInscription(inscription: Inscription) {
    await this.setManuallyVerifiedInscription(inscription, true)
  }

  async disableManuallyVerifyInscription(inscription: Inscription) {
    await this.setManuallyVerifiedInscription(inscription, false)
  }

  private async setManuallyVerifiedInscription(inscription: Inscription, manuallyVerified: boolean) {
    await this.set(
      `sportEventInscriptionsV2/inscriptions/${inscription.sportEvent}/${inscription.date}/${inscription.category}/${inscription.uid}/manuallyVerified`,
      manuallyVerified
    )
    await this.set(
      `sportEventInscriptionsV2/byRider/${inscription.uid}/${inscription.sportEvent}/${inscription.date}/${inscription.category}/manuallyVerified`,
      manuallyVerified
    )
  }

  async setPublicInscription(inscription: PublicInscription) {
    await this.set(
      `sportEventInscriptionsV2/public/${inscription.sportEvent}/${inscription.date}/${inscription.category}/${inscription.uid}`,
      inscription
    )
  }

  async deletePublicInscription(inscription: PublicInscription | Inscription) {
    await this.set(
      `sportEventInscriptionsV2/public/${inscription.sportEvent}/${inscription.date}/${inscription.category}/${inscription.uid}`,
      null
    )
  }

  async setAllPublicInscriptions(
    sportEventId: SportEventId,
    inscriptions: Record<
      DateString,
      Record<InscriptionCategoryID, Record<UserId, PublicInscription>>
    > | null
  ) {
    await this.set(`sportEventInscriptionsV2/public/${sportEventId}`, inscriptions)
  }

  deleteInscriptionBooking(booking: InscriptionBooking) {
    return this.set(`inscriptionBookings/byRider/${booking.uid}/${booking.id}`, null)
  }

  deleteLicenseBooking(booking: LicenseBooking) {
    return this.set(`licenseBookings/byRider/${booking.uid}/${booking.id}`, null)
  }

  async loadLatestBill(user: UserQuery) {
    const bills = await this.loadBills(user)
    return last(sortBy(bills, (bill) => bill.createdAt))
  }

  async loadBills(user: UserQuery, { removeDeleted = true }: { removeDeleted?: boolean } = {}) {
    const bills = await this.loadRecords<Bill>(`bills/byRider/${user.uid}`)
    return bills.filter((bill) => !removeDeleted || !bill.deleted)
  }

  private async loadRecords<T>(path: string) {
    const data = await this.load<Record<string, T>>(path)
    return data ? Object.values(data) : []
  }

  loadBill(reference: string) {
    return this.load<Bill | undefined>(`bills/byReference/${reference}`)
  }

  async storeBill(bill: Bill) {
    await this.set<Bill>(`bills/byReference/${bill.reference}`, bill)
    await this.set<Bill>(`bills/byRider/${bill.uid}/${bill.reference}`, bill)
  }

  async deleteBill(bill: Bill) {
    await this.set(`bills/byReference/${bill.reference}/deleted`, true)
    await this.set(`bills/byRider/${bill.uid}/${bill.reference}/deleted`, true)
  }

  loadAllLicenseDrafts(licenseYear: number) {
    return this.load<Record<UserId, LicenseDrafts>>(`licenses/${licenseYear}/drafts`)
  }

  loadLicenseDrafts(user: UserQuery, licenseYear: number) {
    return this.load<LicenseDrafts>(`licenses/${licenseYear}/drafts/${user.uid}`)
  }

  loadLicenseSubmits(user: UserQuery, licenseYear: number) {
    return this.load<LicenseDrafts>(`licenses/${licenseYear}/submitted/${user.uid}`)
  }

  async allCountries() {
    const c1 = Object.values(await this.loadFairgateContacts()).map((x) => x.country)
    const c2 = Object.values(await this.loadAllDocuments()).map((x) => x.personalData?.country || '')
    return uniq([...c1, ...c2])
  }

  async loadFairgateContacts() {
    return (await this.load<Record<string, { country: string }>>('fairgateContacts')) || {}
  }

  async loadAllUids() {
    return Object.keys(await this.loadAllDocuments())
  }

  async loadAllDocuments() {
    return (await this.load<Record<UserId, Documents>>('documents')) || {}
  }

  async approveLicense(
    { draft, admin }: { draft: LicenseDraftWithDocuments; admin: UserQuery },
    licenseYear: number
  ) {
    const summary = draft.draft.summary
    const categoryDetails = fixedLicenseType(draft.draft.categoryDetails)
    const uid = draft.userId
    const categoryId = draft.draft.categoryId

    if (!summary) throw new Error(`Invalid state: missing summary: ${typeof summary}`)
    if (!categoryDetails)
      throw new Error(`Invalid state: missing categoryDetails: ${typeof categoryDetails}`)

    const association = categoryDetails.licenseAssociation
    const category = categoryOfAssociation(categoryId, association)
    if (!category) throw new Error(`Invalid state: missing category: ${categoryId} ${association}`)

    const preferredNumber = category.numberChoice ? categoryDetails.preferredNumber : '-1'
    if (!validNumber(preferredNumber))
      throw new Error(`Invalid state: invalid preferredNumber: ${preferredNumber}`)

    const approvedLicense: ApprovedLicense = {
      uid,
      categoryId,
      draftProcessedAt: summary.processedAt,
      approvedAt: new Date().toISOString(),
      approvedBy: admin.uid,
      issuedNumber: parseInt10(preferredNumber),
      remarksAdmin: '',
      remarksRider: summary.remarks,
      sidecarPartner: categoryDetails.sidecarPartner,
      paid: false,
      // TODO: later: remove this
      association: 'SAM',
      licenseAssociation: categoryDetails.licenseAssociation,
      licenseType: categoryDetails.licenseType,
      licenseCode: generateCode(),
      pitLanePassCode: generateCode(),
      teamName: categoryDetails.teamName || null,
      bikeMake: categoryDetails.bikeMake || null,
    }

    await this.setApprovedLicense(approvedLicense, licenseYear)
    await this.moveRef(
      `licenses/${licenseYear}/drafts/${uid}/categoryDetails/${categoryId}`,
      `licenses/${licenseYear}/drafts/${uid}/approvedCategoryDetails/${categoryId}`
    )
    const currentCategories = await this.loadLicenseDraftCategoryIds({ uid }, licenseYear)
    const newCategoryIds = currentCategories.filter((id) => id !== categoryId)
    await this.set(`licenses/${licenseYear}/drafts/${uid}/categories`, newCategoryIds)
  }

  async setApprovedCategoryDetails(user: UserQuery, data: ApprovedLicense, licenseYear: number) {
    const { categoryId, issuedNumber, sidecarPartner, teamName, bikeMake } = data

    if (!categoryId || !issuedNumber) throw new Error('categoryID and issuedNumber must be set')

    const license = await this.loadApprovedLicense(user, categoryId, licenseYear)
    if (!license) throw new Error('Can only set details for already approved licenses')
    const updated: ApprovedLicense = {
      ...license,
      issuedNumber,
      sidecarPartner,
      teamName: teamName || null,
      bikeMake: bikeMake || null,
    }
    await this.setApprovedLicense(updated, licenseYear)
  }

  loadApprovedLicense(user: UserQuery, categoryId: CategoryId, licenseYear: number) {
    return this.load<ApprovedLicense | undefined>(
      `licenses/${licenseYear}/approved/${user.uid}/${categoryId}`
    )
  }

  deleteApprovedLicense(approvedLicense: ApprovedLicense, licenseYear: number) {
    return this.set(
      `licenses/${licenseYear}/approved/${approvedLicense.uid}/${approvedLicense.categoryId}`,
      null
    )
  }

  setApprovedLicense(approvedLicense: ApprovedLicense, licenseYear: number) {
    return this.set(
      `licenses/${licenseYear}/approved/${approvedLicense.uid}/${approvedLicense.categoryId}`,
      approvedLicense
    )
  }

  invalidateApprovedLicense(approvedLicense: ApprovedLicense, licenseYear: number) {
    return this.set(
      `licenses/${licenseYear}/approved/${approvedLicense.uid}/${approvedLicense.categoryId}/invalidated`,
      true
    )
  }
  validateApprovedLicense(approvedLicense: ApprovedLicense, licenseYear: number) {
    return this.set(
      `licenses/${licenseYear}/approved/${approvedLicense.uid}/${approvedLicense.categoryId}/invalidated`,
      null
    )
  }

  setApprovedLicensePaid(approvedLicense: ApprovedLicense, paid: boolean, licenseYear: number) {
    return this.set(
      `licenses/${licenseYear}/approved/${approvedLicense.uid}/${approvedLicense.categoryId}/paid`,
      paid
    )
  }

  shortIdMappingByShortId(props: {
    licenseYear: number
    shortId: number
  }): Promise<ShortIdMapping | undefined> {
    const { licenseYear, shortId } = props
    return this.load<ShortIdMapping | undefined>(`licenses/${licenseYear}/approvedByShortId/${shortId}`)
  }

  async setApprovedLicenseShortID(approvedLicense: ApprovedLicense, shortId: number, licenseYear: Year) {
    const { uid, categoryId } = approvedLicense
    const mapping: ShortIdMapping = { uid, categoryId, shortId }
    await this.set(`licenses/${licenseYear}/approved/${uid}/${categoryId}/shortId`, shortId)
    await this.set(
      `licenses/${licenseYear}/approvedByShortId/${shortId}`,
      shortId === 0 ? null : mapping
    )
  }

  async setApprovedLicenseCodes(approvedLicense: ApprovedLicense, licenseYear: Year) {
    await this.set(
      `licenses/${licenseYear}/approved/${approvedLicense.uid}/${approvedLicense.categoryId}/licenseCode`,
      approvedLicense.licenseCode
    )
    await this.set(
      `licenses/${licenseYear}/approved/${approvedLicense.uid}/${approvedLicense.categoryId}/pitLanePassCode`,
      approvedLicense.pitLanePassCode
    )
  }

  setApprovedLicenseCurrentSidecarPartner(
    license: ApprovedLicense,
    sidecarPartner: string,
    licenseYear: number
  ) {
    return this.set(
      `licenses/${licenseYear}/approved/${license.uid}/${license.categoryId}/currentSidecarPartner`,
      sidecarPartner
    )
  }

  async loadLicenseDraftCategoryIds(user: UserQuery, licenseYear: number): Promise<CategoryId[]> {
    return (await this.load<CategoryId[]>(`licenses/${licenseYear}/drafts/${user.uid}/categories`)) || []
  }

  async setLicenseDraftCategoryIds(
    user: UserQuery,
    categories: SelectedCategory[],
    licenseYear: number
  ) {
    const categoryIds = categories.map((x) => x.category)
    await this.set(`licenses/${licenseYear}/drafts/${user.uid}/categories`, uniq(categoryIds))
    const details = await Promise.all(
      categories.map<Promise<[string, CategoryDetails]>>(async ({ association, category }) => [
        category,
        {
          preferredNumber: '',
          sidecarPartner: '',
          licenseType: 'national',
          comment: '',
          ...(await this.loadDraftCategoryDetails(user, category, licenseYear)),
          licenseAssociation: association,
          categoryId: category,
        },
      ])
    )
    await this.setAllDraftCategoryDetails(user, Object.fromEntries(details), licenseYear)
  }

  loadSummary(user: UserQuery, licenseYear: number) {
    return this.load<Summary | undefined>(`licenses/${licenseYear}/drafts/${user.uid}/summary`)
  }

  async setSummary(user: UserQuery, data: Summary, licenseYear: number) {
    await this.set(`licenses/${licenseYear}/drafts/${user.uid}/summary`, data)
  }

  async setAllDraftCategoryDetails(
    user: UserQuery,
    data: Record<string, CategoryDetails>,
    licenseYear: number
  ) {
    await this.set(`licenses/${licenseYear}/drafts/${user.uid}/categoryDetails`, data)
  }

  async setDraftCategoryDetails(user: UserQuery, data: CategoryDetails, licenseYear: number) {
    await this.set(`licenses/${licenseYear}/drafts/${user.uid}/categoryDetails/${data.categoryId}`, data)
  }

  loadDraftCategoryDetails(user: UserQuery, categoryId: CategoryId, licenseYear: number) {
    return this.load<CategoryDetails | undefined>(
      `licenses/${licenseYear}/drafts/${user.uid}/categoryDetails/${categoryId}`
    )
  }

  loadApprovedDraftCategoryDetails(user: UserQuery, categoryId: CategoryId, licenseYear: number) {
    return this.load<CategoryDetails | undefined>(
      `licenses/${licenseYear}/drafts/${user.uid}/approvedCategoryDetails/${categoryId}`
    )
  }

  loadDocuments(user: UserQuery) {
    return this.load<Documents>(`documents/${user.uid}`)
  }

  async setMemberFeesPaymentHistory(user: UserQuery, paymentHistory: Partial<Record<Year, string>>) {
    await this.set(`documents/${user.uid}/memberFeesPaymentHistory`, paymentHistory)
  }

  async setMemberFeesPaidAt(user: UserQuery, paidAt: string) {
    await this.set(`documents/${user.uid}/memberFeesPaidAt`, paidAt)
  }

  async deleteMemberFeesPaidAt(user: UserQuery) {
    await this.set(`documents/${user.uid}/memberFeesPaidAt`, null)
  }

  async setPersonalData(user: UserQuery, data: PersonalData) {
    const newData = { ...data }
    await this.set(`documents/${user.uid}/personalData`, newData)
  }

  async setPersonalDataEmail(user: UserQuery, email: string) {
    await this.set(`documents/${user.uid}/personalData/email`, email)
  }

  loadPersonalData(user: UserQuery) {
    return this.load<PersonalData>(`documents/${user.uid}/personalData`)
  }

  setUser(user: UserQuery, attributes: UserAttributes) {
    return this.set(`users/${user.uid}`, attributes)
  }

  setUserEmail(user: UserQuery, email: string) {
    return this.set(`users/${user.uid}/email`, email)
  }

  loadUserData(user: UserQuery) {
    return this.load<UserData>(`users/${user?.uid || '-'}`)
  }

  async setTransponder(user: UserQuery, original: Transponder) {
    const transponder: Transponder = {
      orderedTransponders: original.orderedTransponders || [],
      transponders: original.transponders || {},
    }
    await this.set(`documents/${user.uid}/transponder`, transponder)
  }

  loadTransponder(user: UserQuery) {
    return this.load<Transponder>(`documents/${user.uid}/transponder`)
  }

  async setEmergency(user: UserQuery, data: Emergency) {
    await this.set(`documents/${user.uid}/emergency`, data)
  }

  async setDriversLicense(user: UserQuery, data: DriversLicense) {
    await this.set(`documents/${user.uid}/driversLicense`, data)
  }

  async setLastYear(user: UserQuery, data: LastYear) {
    await this.set(`documents/${user.uid}/lastYear`, data)
  }

  async setHealthCheckUpload(user: UserQuery, page: number, metadata: UploadMetadata) {
    await this.set(`documents/${user.uid}/healthCheck/page${page}`, metadata)
  }

  async setHealthCheckStatus(user: UserQuery, statusDetails: UploadStatusDetails) {
    await this.set(`documents/${user.uid}/healthCheck/status`, statusDetails.status)
    await this.set(`documents/${user.uid}/healthCheck/statusDetails`, statusDetails)
  }

  async deleteHealthCheckUpload(user: UserQuery, page: number) {
    await this.set(`documents/${user.uid}/healthCheck/page${page}`, null)
  }

  async setInsuranceUpload(user: UserQuery, page: number, metadata: UploadMetadata) {
    await this.set(`documents/${user.uid}/insurance/page${page}`, metadata)
  }

  async setInsuranceStatus(user: UserQuery, statusDetails: UploadStatusDetails) {
    await this.set(`documents/${user.uid}/insurance/status`, statusDetails.status)
    await this.set(`documents/${user.uid}/insurance/statusDetails`, statusDetails)
  }

  async deleteInsuranceUpload(user: UserQuery, page: number) {
    await this.set(`documents/${user.uid}/insurance/page${page}`, null)
  }

  async setPhotoUpload(user: UserQuery, metadata: UploadMetadata) {
    await this.set(`documents/${user.uid}/photo/upload`, metadata)
  }

  async setPhotoStatus(user: UserQuery, statusDetails: UploadStatusDetails) {
    await this.set(`documents/${user.uid}/photo/status`, statusDetails.status)
    await this.set(`documents/${user.uid}/photo/statusDetails`, statusDetails)
  }

  async deletePhotoUpload(user: UserQuery) {
    await this.set(`documents/${user.uid}/photo/upload`, null)
  }

  pushBike(user: UserQuery, bike: Bike) {
    return this.push(`documents/${user.uid}/bikes`, { ...bike, uid: user.uid })
  }

  async loadBikes(user: UserQuery) {
    const bikes = (await this.load<Record<string, Bike>>(`documents/${user.uid}/bikes`)) || {}
    return Object.values(bikes)
  }

  loadBike(user: UserQuery, bikeID: string) {
    return this.load<Bike>(`documents/${user.uid}/bikes/${bikeID}`)
  }

  deleteBike(bike: Bike) {
    return this.set(`documents/${bike.uid}/bikes/${bike.id}/status`, 'deleted')
  }

  setAdmin(path: AdminPath, user: StoredUser) {
    return this.set(`${path}/${user.uid}`, true)
  }

  setAssociationAdmin(user: StoredUser, association: AssociationID) {
    return this.set(`associationAdmins/${user.uid}/${association}`, true)
  }

  removeAdmin(path: AdminPath, user: StoredUser) {
    return this.set(`${path}/${user.uid}`, null)
  }

  removeAssociationAdmin(user: StoredUser, association: AssociationID) {
    return this.set(`associationAdmins/${user.uid}/${association}`, null)
  }

  storeFrontendError(user: UserQuery | undefined, error: any) {
    return user ? this.storeFrontendErrorUser(user, error) : this.storeFrontendErrorAnonymous(error)
  }

  private storeFrontendErrorUser({ uid }: UserQuery, error: any) {
    return this.push(
      `errors/frontend/byRider/${uid}`,
      removeUndefined({ id: '', date: new Date().toISOString(), ...error })
    )
  }

  private storeFrontendErrorAnonymous(error: any) {
    return this.push(
      `errors/frontend/anonymous`,
      removeUndefined({ id: '', date: new Date().toISOString(), ...error })
    )
  }

  setActiveSportEvent({ uid }: UserQuery, sportEventId: string) {
    return this.set(`activeSportEvents/byUser/${uid}`, sportEventId)
  }

  loadUserLocale(user: UserQuery) {
    return this.load<I18nLocales | undefined>(`documents/${user.uid}/locale`)
  }

  async setUserLocale(user: UserQuery, locale: I18nLocales) {
    await this.set(`documents/${user.uid}/locale`, locale)
  }

  async overrideTeamAndBike(props: {
    user: UserQuery
    association: AssociationID
    override: TeamAndBikeOverride
  }) {
    const { user, association, override } = props
    await this.set(`documents/${user.uid}/teamOverrides/${association}`, override)
  }

  async deleteTeamAndBikeOverride(props: { user: UserQuery; association: AssociationID }) {
    const { user, association } = props
    await this.delete(`documents/${user.uid}/teamOverrides/${association}`)
  }

  async pushUserEvent(fullUserEvent: UserEvent) {
    const { details, ...userEventWithOrWithoutID } = fullUserEvent

    const userEvent = await this.pushOrSetUserEvent(userEventWithOrWithoutID)
    await this.set(`userEvents2/byUser/${userEvent.uid}/${userEvent.type}/${userEvent.id}`, userEvent)
    await this.set(
      `userEvents2/doneByUser/${userEvent.byUid}/${userEvent.type}/${userEvent.id}`,
      userEvent
    )
    if (details)
      await this.set(`userEvents2/details/${userEvent.uid}/${userEvent.type}/${userEvent.id}`, details)
  }

  private async pushOrSetUserEvent(userEvent: UserEvent) {
    if (userEvent.id && userEvent.id.startsWith('-')) {
      await this.set(`userEvents2/all/${userEvent.id}`, userEvent)
      return userEvent
    }
    return await this.push('userEvents2/all', userEvent)
  }

  async delete(path: string) {
    await this.set(path, null)
  }

  async set<T>(path: string, data: T): Promise<void> {
    await this.ref(path).set(data)
  }

  async exists(path: string): Promise<boolean> {
    return (await this.ref(path).once('value')).exists()
  }

  async load<T>(path: string): Promise<T | undefined> {
    return (await this.ref(path).once('value')).val()
  }

  abstract moveRef(from: string, to: string): Promise<void>

  abstract push<T extends { id?: string | undefined }>(path: string, data: T): Promise<T>

  abstract ref(path: string): firebase.database.Reference
}

export function removeUndefined<T>(obj: T): T {
  return JSON.parse(JSON.stringify(obj))
}

export interface PublicInscriptionStatus {
  enlisted: boolean
  paid: boolean
  enlistedAt: string
  // TODO: later: paidAt: string
}

export interface NewManualPayment {
  bill?: Bill
  date: string
  amount: number
  byUid: string
  tag: string
  association: AssociationID
  uid: UserId
  reference: string
  internalRemarks?: string | null
}

export type AdminPath = 'admins' | 'readonlyAdmins'

export interface AdminRider {
  admin: UserQuery
  rider: UserQuery
}

export interface UserQuery {
  uid: string
}

export interface UserData {
  uid: string
  email: string
  emailVerified: boolean
  imported: boolean
}

export interface LicenseFormData {
  documents?: Documents
  licenseDrafts?: LicenseDrafts
  approvedLicenses: ApprovedLicense[]
}

export interface DocumentsWithUid extends Documents {
  uid: UserId
}

export interface Documents {
  personalData?: PersonalData
  lastYear?: LastYear
  transponder?: Transponder
  emergency?: Emergency
  healthCheck?: HealthCheck
  insurance?: Insurance
  driversLicense?: DriversLicense
  photo?: Photo
  memberFeesPaidAt?: string
  memberFeesPaymentHistory?: Partial<Record<Year, string>>
  bikes?: Record<string, Bike>
  teamOverrides?: Partial<Record<AssociationID, TeamAndBikeOverride>>
  locale?: I18nLocales
}

export interface TeamAndBikeOverride {
  bikeMake: string
  teamName: string
}

export interface LicenseDrafts {
  categories?: CategoryId[]
  categoryDetails?: Record<string, CategoryDetails>
  approvedCategoryDetails?: Record<string, CategoryDetails>
  summary?: Summary
}

export interface ApprovedLicense {
  shortId?: number
  uid: string

  categoryId: CategoryId
  issuedNumber: number
  // TODO: later: migrate this to `legacyAssociation`
  association: undefined | Association
  // later: legacyAssociation: string

  // TODO: later: migrate this: empty values should become 'sam'
  licenseAssociation: AssociationID
  // TODO: later: migrate this: empty values should become 'national'
  licenseType: LicenseType
  currentSidecarPartner?: string | undefined
  sidecarPartner?: string | undefined

  teamName?: string | null | undefined
  bikeMake?: string | null | undefined

  remarksRider: string
  remarksAdmin: string

  draftProcessedAt: string
  approvedAt: string
  approvedBy: string

  invalidated?: boolean

  paid: boolean

  licenseCode: string | undefined
  pitLanePassCode: string | undefined
}

export interface InscriptionWithContextAndSportEvent {
  inscription: Inscription
  licenseWithContext: ApprovedLicenseWithContext | undefined
  status: InscriptionStatus
  sportEvent: SportEvent
  documents: Documents
  dayCategory: DayCategory | undefined
}

export interface InscriptionWithContext {
  inscription: EnlistedInscription
  licenseWithContext: ApprovedLicenseWithContext
  status: InscriptionStatus
}

export interface ApprovedLicenseWithContext extends LicenseWithContextBase {
  license: LicenseDraftWithDocumentsApproved
}

export interface DocumentsWithLicenseContexts {
  uid: UserId
  documents: DocumentsWithUid
  licensesWithContext: LicenseWithContext[]
}

export interface LicenseWithContext extends LicenseWithContextBase {
  license: LicenseDraftWithDocuments
}

interface LicenseWithContextBase {
  conflicts: LicenseDraftWithDocuments[]
  others: LicenseDraftWithDocuments[]
  all: LicenseDraftWithDocuments[]
  approved: ApprovedLicense[]
  tasks: LicenseTasksOverview
}

export type LicenseDraftWithDocuments =
  | LicenseDraftWithDocumentsDraft
  | LicenseDraftWithDocumentsApproved

export interface LicenseDraftWithDocumentsDraft {
  id: string
  userId: string
  draft: SingleLicenseDraft
  documents: Documents
  association: AssociationID
  type: 'draft'
}

export interface LicenseDraftWithDocumentsApproved {
  id: string
  userId: string
  draft: SingleLicenseDraft
  documents: Documents
  approved: ApprovedLicense
  association: AssociationID
  type: 'approved'
}

export interface SingleLicenseDraft {
  categoryId: CategoryId
  category: Category
  categoryDetails?: CategoryDetails
  summary?: Summary
}

export interface TransactionsByType {
  licenseBookings: LicenseBooking[]
  manualPayments: ManualPayment[]
  automaticPayments: AutomaticPayment[]
  manualBookings: ManualBooking[]
  inscriptionBookings: InscriptionBooking[]
}

export interface LicenseBookingsByLineItemType {
  categoryLineItems: LicenseBookingWithCategoryLineItem[]
  transponderLineItems: LicenseBookingWithTransponderLineItem[]
}

export type TransactionWithTotal = WithTotal<Transaction>

export type RefinedBookingRelevantTransaction = WithTotal<BookingRelevantTransactionPure>

export interface FilteredBookingRelevantTransaction {
  transaction: BookingRelevantTransaction
  uidName: string
  byUidName: string
}

export type BookingRelevantTransaction = WithTotal<BookingRelevantTransactionPure>

export type BookingRelevantTransactionPure =
  | Payment
  | ManualBooking
  | InscriptionBooking
  | LicenseBooking
  | AssociationPaymentRequested
  | AssociationPaymentPaid

export type Transaction =
  | AutomaticPaymentWithoutUid
  | ManualPayment
  | ManualBooking
  | InscriptionBooking
  | NormalLicenseBooking
  | ReverseLicenseBooking
  | Bill
  | AssociationPaymentRequested
  | AssociationPaymentPaid

export type WithTotal<T> = T & { currentTotal: number }

export interface Bill {
  type: 'bill'
  createdAt: string
  updatedAt: string
  paidAt: string
  uid: string
  byUid: string
  title: string
  filename: string
  reference: string
  date: string
  deleted?: true
  status: 'open' | 'paid' | 'overpaid' | 'underpaid' | 'replaced'
  paymentId?: string
  items: BillLineItem[] | undefined
  paymentInfo: PaymentInfo
  remainingBalance?: number
}

export interface PaymentInfo {
  currency: 'CHF' | 'EUR'
  amount: number
  reference: string
  creditor: PlatformCreditor
  debitor: {
    name: string
    address: string
    zip: number | string
    city: string
    country: string
  }
}

export interface ManualBooking {
  type: 'manualBooking'
  id: string
  uid: string
  byUid: string
  date: string
  item: ManualBookingBillLineItem
  internalRemarks?: string | null
  categoryId?: CategoryId | null
  sportEventId?: SportEventId | null
  createdAt: string
  updatedAt: string
  // this value is not written to the database - it is only used for intermediate calculations
  remainingBalance?: number
  tag: string
}

export type LicenseBooking = NormalLicenseBooking | ReverseLicenseBooking
export type LicenseBookingWithCategoryLineItem =
  | NormalLicenseBookingWithCategoryLineItem
  | ReverseLicenseBookingWithCategoryLineItem
export type LicenseBookingWithTransponderLineItem =
  | NormalLicenseBookingWithTransponderLineItem
  | ReverseLicenseBookingWithTransponderLineItem

export interface InscriptionBooking {
  type: 'inscriptionBooking'
  id: string | undefined
  uid: string
  byUid: string
  date: string
  item:
    | InscriptionLineItem
    | PowerLineItem
    | InscriptionDayCategoryLineItem
    | InscriptionDayCategoryPowerLineItem
    | InscriptionDayLicenseLineItem
    | DonationLineItem
    | DiscountLineItem
  // this value is written to the database and available to the frontend
  remainingBalance: number
  internalRemarks?: string | null
  // TODO: later: updatedAt: string
}

export interface NormalLicenseBooking extends NormalLicenseBookingBase {
  item: LicenseLineItem
}

export interface NormalLicenseBookingWithCategoryLineItem extends NormalLicenseBookingBase {
  item: CategoryLineItem
}

export interface NormalLicenseBookingWithTransponderLineItem extends NormalLicenseBookingBase {
  item: TransponderLineItem
}

interface NormalLicenseBookingBase {
  type: 'licenseBooking'
  id: string | undefined
  uid: string
  byUid: string
  date: string
  internalRemarks?: string | null
  year: number
  remainingBalance: number
  // TODO: later: updatedAt: string
}

export interface ReverseLicenseBooking extends ReverseLicenseBookingBase {
  item: LicenseLineItem
}

export interface ReverseLicenseBookingWithCategoryLineItem extends ReverseLicenseBookingBase {
  item: CategoryLineItem
}

export interface ReverseLicenseBookingWithTransponderLineItem extends ReverseLicenseBookingBase {
  item: TransponderLineItem
}

export interface ReverseLicenseBookingBase {
  type: 'reverseLicenseBooking'
  id: string | undefined
  uid: string
  byUid: string
  date: string
  internalRemarks?: string | null
  year: number
  // this value is written to the database and available to the frontend
  remainingBalance: number
  association?: AssociationID
}

export interface ManualBookingBillLineItem {
  type: 'billLineItem'
  name: string
  price: number
  association: AssociationID
}

export interface BillLineItem {
  type: 'billLineItem'
  name: string
  price: number
  association?: AssociationID
}

export interface InscriptionLineItem extends LineItem {
  type: 'inscriptionLineItem'
  categoryId: CategoryId
  sportEventId: string
  // TODO: later: migrate this
  sportEventDate: string | undefined
  // TODO: later: updatedAt: string
}

export interface InscriptionDayCategoryLineItem extends LineItem {
  type: 'inscriptionDayCategoryLineItem'
  categoryId: DayCategoryID
  dayCategoryName: string
  sportEventId: string
  sportEventDate: string
}

export interface InscriptionDayLicenseLineItem extends LineItem {
  type: 'inscriptionDayLicenseLineItem'
  categoryId: CategoryId
  sportEventId: string
  sportEventDate: string
}

export interface PowerLineItem extends LineItem {
  type: 'powerLineItem'
  categoryId: CategoryId
  sportEventId: string
  // TODO: later: migrate this
  sportEventDate: string | undefined
}

export interface InscriptionDayCategoryPowerLineItem extends LineItem {
  type: 'inscriptionDayCategoryPowerLineItem'
  categoryId: DayCategoryID
  dayCategoryName: string
  sportEventId: string
  sportEventDate: string
}

export interface DonationLineItem extends LineItem {
  type: 'donationLineItem'
  categoryId: CategoryId | DayCategoryID
  sportEventId: string
  categoryName: string
  sportEventDate: string
}

export interface DiscountLineItem extends LineItem {
  type: 'inscriptionDiscountLineItem'
  sportEventId: string
  categoryId: CategoryId
  categoryName: string
  sportEventDate: ''
}

export type LicenseLineItem = CategoryLineItem | TransponderLineItem

export interface CategoryLineItem extends LineItem {
  type: 'categoryLineItem'
  subtype: 'mainLicense' | 'additionalLicense' | 'licenseDiscount' | 'licenseSurcharge'
  reverse: boolean
  categoryId: CategoryId
}

export interface TransponderLineItem extends LineItem {
  type: 'transponderLineItem'
  subtype: 'transponder'
  reverse: boolean
  transponderId: TransponderOptionId
  transponderType: TransponderType
}

export interface LineItem {
  name: string
  price: number
  association: AssociationID // | IndependentAssociationID
}

export type Payment = AutomaticPayment | ManualPayment
export type PaymentWithoutUid = AutomaticPaymentWithoutUid | ManualPayment

export interface InvalidPayment extends AutomaticPaymentWithoutUid {
  originalReference: string
}

export interface AutomaticPayment {
  type: 'payment'
  date: string
  id: string
  reference: string
  originalReference?: string
  amount: number
  raw: string
  debitor: Debitor
  ultimateDebitor: Debitor
  debitorIban: string
  // this value is not written to the database - it is only used for intermediate calculations
  remainingBalance?: number
  uid: UserId
}

export interface AutomaticPaymentWithoutUid {
  type: 'payment'
  date: string
  id: string
  reference: string
  originalReference?: string
  amount: number
  raw: string
  debitor: Debitor
  ultimateDebitor: Debitor
  debitorIban: string
  // this value is not written to the database - it is only used for intermediate calculations
  remainingBalance?: number
}

export interface ManualPayment {
  type: 'manualPayment'
  date: string
  id: string
  byUid: string
  reference: string
  amount: number
  uid: UserId
  // this value is not written to the database - it is only used for intermediate calculations
  remainingBalance?: number
  tag: string
  association: AssociationID
  internalRemarks?: string | null
  createdAt: string
  updatedAt: string
}

export interface PaymentFile {
  id: string
  filename: string
  parsed: ParsedPayment
}

export interface UnparsedPaymentFile {
  id: string
  filename: string
  parsed: ParsedPayment
}

export interface ShortUids {
  uidToShortUid: Record<string, string>
  shortUidToUid: Record<string, string>
}

export type AssociationPayment = AssociationPaymentRequested | AssociationPaymentPaid

export interface AssociationPaymentRequested {
  id: string
  association: AssociationID
  type: 'associationPayment'
  status: 'requested'
  amount: number
  internalRemarks: string
  paymentReference?: null

  // date of the booking, desired date of the payment
  date: DateString
  desiredDate?: null

  createdAt: DateString
  updatedAt: DateString
  requestedByUid: UserId
  paidByUid?: null

  remainingBalance?: undefined
}

export interface AssociationPaymentPaid {
  id: string
  association: AssociationID
  type: 'associationPayment'
  status: 'paid'
  amount: number
  internalRemarks: string
  paymentReference: string

  // date of the booking, date of the payment
  date: DateString
  desiredDate: DateString

  createdAt: DateString
  updatedAt: DateString
  requestedByUid: UserId
  paidByUid: UserId

  remainingBalance?: undefined
}

export function isNotAssociationPayment<T extends Transaction>(
  t: T
): t is Exclude<T, AssociationPayment> {
  return t.type !== 'associationPayment'
}

export function isAssociationPayment<T extends Transaction>(t: T): t is Extract<T, AssociationPayment> {
  return t.type === 'associationPayment'
}

export function isAssociationPaymentRequested<T extends AssociationPayment>(
  t: T
): t is Extract<T, AssociationPaymentRequested> {
  return t.status === 'requested'
}

export function isAssociationPaymentPaid<T extends AssociationPayment>(
  t: T
): t is Extract<T, AssociationPaymentPaid> {
  return t.status === 'paid'
}

export function isNotPayment(
  row: BookingRelevantTransaction
): row is Exclude<BookingRelevantTransaction, AssociationPayment | Payment | ManualPayment> {
  return row.type !== 'payment' && row.type !== 'manualPayment' && row.type !== 'associationPayment'
}

export function isManualPayment(payment: ManualPayment | AutomaticPayment): payment is ManualPayment {
  return payment.type === 'manualPayment'
}

export type WithoutTimestamps<T> = Omit<T, 'createdAt' | 'updatedAt'>

export interface UserAttributes {
  uid: string
  email: string
  emailVerified: boolean
  imported?: boolean
}
